export function generateStyle(key, parentKey) {
    switch (key) {
        case "escalated":
            return escalatedStyle.itemStyle;
        case "inProgress":
            return inProgressClosedStyle["72h"].itemStyle;
        case "closed":
            return inProgressClosedStyle["72h"].itemStyle;
        case "canceled":
            return canceledStyle.itemStyle;
        case "lessThan72h":
            return inProgressClosedStyle["72h"].itemStyle;
        case "between72hAnd120h":
            return inProgressClosedStyle["120h"].itemStyle;
        case "moreThan120h":
            return inProgressClosedStyle["120h"].itemStyle;
        case "rootCauseNotFound":
            return generateStyle(parentKey);
        case "rootCauseFound":
            return generateStyle(parentKey);
    }
}
export function isEscalated(data) {
    return data.abReport;
}
export function isClosed(data) {
    return !!data.closedAt;
}
export function isCanceled(data) {
    return data.canceled;
}
export function getDelta(data) {
    var createdAt = data.createdAt, closedAt = data.closedAt;
    var delta;
    if (closedAt)
        delta = Date.parse(closedAt) - Date.parse(createdAt);
    else
        delta = Date.now() - Date.parse(createdAt);
    return delta;
}
export function isRootCauseFound(data) {
    return data.rootCauseFound;
}
var status_green = "rgba(0, 178, 97, 1)";
var status_yellow = "rgba(255, 144, 64, 1)";
var status_red = "rgba(239, 96, 96, 1)";
var status_blue = "rgba(65, 111, 203, 1)";
var status_grey = "rgba(102, 102, 102, 1)";
var canceledStyle = {
    itemStyle: {
        color: status_grey,
    },
};
var escalatedStyle = {
    itemStyle: {
        color: status_blue,
    },
};
var inProgressClosedStyle = {
    "72h": {
        itemStyle: {
            color: status_green,
        },
    },
    "72h-120h": {
        itemStyle: {
            color: status_yellow,
        },
    },
    "120h": {
        itemStyle: {
            color: status_red,
        },
    },
};
