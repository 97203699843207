var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Title.scss";
import { useTranslation } from "@dws/dws-profile";
import { useState } from "react";
import { useMeasure } from "dws-reports/hooks/useMeasure";
import { Loading } from "@dws/dws-styleguide";
export function Title(_a) {
    var reportName = _a.reportName, reportTypes = _a.reportTypes, setReportType = _a.setReportType, _b = _a.showDivider, showDivider = _b === void 0 ? true : _b, _c = _a.showInfo, showInfo = _c === void 0 ? true : _c, _d = _a.type, type = _d === void 0 ? "internal" : _d, children = _a.children, _e = _a.isLoading, isLoading = _e === void 0 ? false : _e;
    var _f = useState(false), showTooltip = _f[0], setShowTooltip = _f[1];
    var _g = useMeasure({ eagerObserver: false }), ref = _g[0], width = _g[1].width;
    var t = useTranslation().t;
    var title = t("reports.".concat(reportName));
    var info = t("reports.".concat(reportName.replace(/ /g, "_").toLowerCase(), "_info"));
    var isMobile = width < 764;
    var options = reportTypes === null || reportTypes === void 0 ? void 0 : reportTypes.map(function (reportType, index) { return (_jsx("option", __assign({ value: reportType }, { children: reportType.toUpperCase() }), index)); });
    function selectReportType(e) {
        setReportType(e.target.value);
    }
    var handleTitleClick = function () {
        if (isMobile) {
            setShowTooltip(!showTooltip);
        }
    };
    var showSelect = reportTypes && reportTypes.length > 0;
    var titleClassName = ["title", type].join(" ");
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ ref: ref, className: titleClassName }, { children: [_jsx("span", __assign({ "data-testid": "title" }, { children: title })), isLoading ? _jsx(Loading, { size: "small", style: { marginRight: "auto" } }) : null, showSelect && (_jsx("select", __assign({ "data-testid": "select", name: "reportType", id: "test", onChange: selectReportType }, { children: options }))), children, showInfo && _jsx("span", { onClick: handleTitleClick, className: "info-icon", title: info }), showTooltip && _jsx("div", __assign({ className: "tooltip" }, { children: info }))] })), showDivider && _jsx("hr", {})] }));
}
