var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { SunburstChart } from "./ReportTypes/Sunburst/Sunburst";
import { DoughnutReport } from "./ReportTypes/Doughnut/Doughnut";
import { Title } from "../../Title/Title";
import { EchartsContextProvider } from "dws-reports/contexts/echartsContext/echartsContext";
import "./FivewhyDoughnutReport.scss";
export function FivewhyDoughnutReport(_a) {
    var data = _a.data;
    var _b = useState("doughnut"), type = _b[0], setType = _b[1];
    var fivewhys = data.fivewhys;
    var EmptyResult = _jsx(ErrorDisplay, { message: "No Fivewhys", icon: "SMILE" });
    if (!fivewhys)
        return EmptyResult;
    if (fivewhys.length === 0)
        return EmptyResult;
    var Report = function (_a) {
        var type = _a.type;
        if (type === "sunburst") {
            return _jsx(SunburstChart, { data: fivewhys });
        }
        return _jsx(DoughnutReport, { data: fivewhys });
    };
    var reportTypes = ["doughnut", "sunburst"];
    function setReportType(reportType) {
        setType(reportType);
    }
    return (_jsxs("div", __assign({ className: "wrapper" }, { children: [_jsx(Title, { reportName: "5_why", reportTypes: reportTypes, setReportType: setReportType }), _jsx(EchartsContextProvider, { children: _jsx(Report, { type: type }) })] })));
}
