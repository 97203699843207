var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SMILE_ICON from "../../../../../public/img/SMILE.png";
import "./ErrorDisplay.scss";
export function ErrorDisplay(_a) {
    var message = _a.message, icon = _a.icon;
    return (_jsxs("div", __assign({ className: "error-wrapper" }, { children: [_jsx("img", { className: "icon", src: SMILE_ICON }), _jsx("p", __assign({ className: "message", "data-testid": "error-message" }, { children: message }))] })));
}
