var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEscalated, isClosed, isCanceled, getDelta, isRootCauseFound, generateStyle, } from "./helpers";
var seventyTwoHours = 72 * 60 * 60 * 1000;
var oneHundredAndTwentyHours = 120 * 60 * 60 * 1000;
var childObj = function () { return ({ rootCauseFound: [], rootCauseNotFound: [] }); };
var baseDeltaObj = function () { return ({
    lessThan72h: __assign({}, childObj()),
    between72hAnd120h: __assign({}, childObj()),
    moreThan120h: __assign({}, childObj()),
}); };
/**
 * Determine the key based on the input delta.
 */
function getKeyBasedOnDelta(delta) {
    var key = delta < seventyTwoHours ? "lessThan72h" : "between72hAnd120h";
    key = delta > oneHundredAndTwentyHours ? "moreThan120h" : key;
    return key;
}
/**
 * Create a base object for a given serie.
 */
function buildBaseObjectForSerie(seriesWithDelta, serie) {
    return seriesWithDelta.includes(serie)
        ? __assign({}, baseDeltaObj()) : __assign({}, childObj());
}
/**
 * Update the entries for a given serie.
 */
function handleSerieEntry(fivewhy, options, serie, rootCauseFound, key) {
    var target = key ? options[serie][key] : options[serie];
    if (rootCauseFound) {
        target.rootCauseFound.push(fivewhy);
    }
    else {
        target.rootCauseNotFound.push(fivewhy);
    }
}
/**
 * Process an individual fivewhy object, updating the entries.
 */
function processFivewhy(fivewhy, options, seriesWithDelta, chartSeries) {
    var escalated = isEscalated(fivewhy);
    var closed = isClosed(fivewhy);
    var canceled = isCanceled(fivewhy);
    var delta = getDelta(fivewhy);
    var rootCauseFound = isRootCauseFound(fivewhy);
    var key = getKeyBasedOnDelta(delta);
    for (var _i = 0, chartSeries_1 = chartSeries; _i < chartSeries_1.length; _i++) {
        var serie = chartSeries_1[_i];
        // Initialize the object
        if (!options[serie]) {
            options[serie] = buildBaseObjectForSerie(seriesWithDelta, serie);
        }
        if ((serie === "escalated" && escalated) ||
            (serie === "canceled" && canceled)) {
            handleSerieEntry(fivewhy, options, serie, rootCauseFound);
        }
        else if ((serie === "inProgress" && !escalated && !closed && !canceled) ||
            (serie === "closed" && closed && !escalated && !canceled)) {
            handleSerieEntry(fivewhy, options, serie, rootCauseFound, key);
        }
    }
}
export function generateSunburstOptions(data) {
    var chartSeries = ["escalated", "inProgress", "closed", "canceled"];
    var seriesWithDelta = ["inProgress", "closed"];
    var options = {};
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var fivewhy = data_1[_i];
        processFivewhy(fivewhy, options, seriesWithDelta, chartSeries);
    }
    return options;
}
export function generateSunburstChartOptions(data, parentKey) {
    return Object.keys(data).map(function (key) {
        if (Array.isArray(data[key])) {
            return {
                name: key,
                itemStyle: generateStyle(key, parentKey),
                value: data[key].length,
            };
        }
        else {
            return {
                name: key,
                itemStyle: generateStyle(key),
                children: generateSunburstChartOptions(data[key], key),
            };
        }
    });
}
