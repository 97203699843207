import { z } from "zod";
export var reportTypeSchema = z.union([
    z.literal("line-performance"),
    z.literal("plan-attainment"),
    z.literal("fivewhy-doughnut"),
    z.literal("top-faults"),
    z.literal("actions-list"),
    z.literal("individual-routines-list"),
    z.literal("collaborative-routines-list"),
    z.literal("fivewhy-list"),
], {
    description: "Report type",
    invalid_type_error: "INVALID_REPORT_TYPE",
});
