import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef } from "react";
import { useEcharts } from "dws-reports/hooks/useEcharts";
import { EchartsContext } from "dws-reports/contexts/echartsContext/echartsContext";
import { z } from "zod";
var schema = z.object({
    options: z.object({
        grid: z.any().optional(),
        tooltip: z.any().optional(),
        series: z.array(z.any()),
        xAxis: z.any().optional(),
        yAxis: z.any().optional(),
    }),
    style: z.optional(z.any({})),
    testId: z.optional(z.string()),
    onClick: z.optional(z.function()),
});
export var EchartsWrapperSchema = schema;
export function EchartsWrapper(props) {
    var chartRef = useRef(null);
    var echartsContext = useContext(EchartsContext);
    var _a = schema.parse(props), options = _a.options, style = _a.style, testId = _a.testId, onClick = _a.onClick;
    var chart = useEcharts({ options: options, ref: chartRef }).chart;
    chart === null || chart === void 0 ? void 0 : chart.on("click", onClick);
    useEffect(function () {
        echartsContext === null || echartsContext === void 0 ? void 0 : echartsContext.setEcharts(chart);
    }, [chart]);
    var dataTestId = [testId, "echarts-instance"].filter(Boolean).join("-");
    return _jsx("div", { ref: chartRef, style: style, "data-testid": dataTestId });
}
