var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./RoutineExecutionReport.scss";
import { useTranslation } from "@dws/dws-profile";
import { Title } from "../../Title/Title";
import ReportSection from "./components/ReportSection";
import navigateTo from "./helpers/navigateTo";
import { useEventBusLastCall } from "dws-reports/hooks/useEventBus";
import { CustomEventType } from "dws-reports/types/CustomEventType";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { LoadingReport } from "../../shared/LoadingReport";
import { useRoutineExecutionData } from "./hooks/useRoutineExecutionData";
export function RoutineExecutionReport(_a) {
    var authenticationData = _a.authenticationData;
    var t = useTranslation().t;
    var filters = (useEventBusLastCall(CustomEventType.FILTERS_CHANGED) || {}).payload;
    var _b = useRoutineExecutionData(filters, authenticationData), isLoading = _b.isLoading, error = _b.error, data = _b.data, isFetching = _b.isFetching;
    if (error)
        return _jsx(ErrorDisplay, { message: error.message, icon: "SMILE" });
    if (isLoading || Object.keys(data).length === 0)
        return (_jsx(LoadingReport, { isFetching: isFetching, isLoading: isLoading, reportName: "routine_execution" }));
    return (_jsxs("div", __assign({ className: "wrapper" }, { children: [_jsx(Title, { isLoading: isFetching && !isLoading, reportName: "routine_execution" }), _jsxs("section", __assign({ className: "report", "data-testid": "routine_execution" }, { children: [_jsx(ReportSection, { title: t("actions.actions"), value: data.actionPercentage, onClick: function () { return navigateTo("actions"); } }), _jsx(ReportSection, { title: t("reports.individual_routines"), value: data.individualRoutinesPercentage, onClick: function () { return navigateTo("routines"); } }), _jsx(ReportSection, { title: t("reports.collaborative_routines"), value: data.collaborativeRoutinesPercentage, onClick: function () { return navigateTo("routines"); } })] }))] })));
}
