import { jsx as _jsx } from "react/jsx-runtime";
import { config } from "@dws/dws-auth";
import { navigateToDeepLink } from "dws-reports/functions/navigateToDeepLink";
import { DWSLinkIcon } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";
export function FivewhyDetailsLink(_key, fivewhy) {
    var t = useTranslation().t;
    var fiveWhyId = fivewhy.fiveWhyId;
    if (!fiveWhyId)
        return null;
    return (_jsx(DWSLinkIcon, { "data-testid": "fivewhy-detail-button", style: { cursor: "pointer" }, title: t("reports.details"), onClick: function () {
            return navigateToDeepLink({
                url: "".concat(config.IAL_BASE_URL, "/problem-terminator/details/").concat(fiveWhyId),
                params: {}
            });
        } }));
}
