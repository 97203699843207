var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from "../../Title/Title";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { ColouredNumberBloc } from "../../report-helpers/ColouredNumberBloc";
import "./PlanAttainmentReport.scss";
import { useTranslation } from "@dws/dws-profile";
export function PlanAttainmentReport(_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var value = data.value;
    if ([101, 102, 103, 104].includes(value)) {
        return (_jsx(ErrorDisplay, { message: t("plan_attainment.".concat(value)), icon: "SMILE" }));
    } // TODO: add tests
    return (_jsxs("div", __assign({ style: wrapperStyle }, { children: [_jsx(Title, { reportName: "plan_attainment" }), _jsx("div", __assign({ className: "plan-attainment-wrapper" }, { children: _jsx(ColouredNumberBloc, { value: value, backgroundColor: getColorByValue(value), color: "black" }) }))] })));
}
var wrapperStyle = {
    backgroundColor: "#292929",
    borderRadius: "8px",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    height: "100%",
};
var baseReportStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    margin: "2rem 1rem",
};
export function getReportStyle(value) {
    return __assign(__assign({}, baseReportStyle), { backgroundColor: getColorByValue(value), color: "rgba(14, 14, 14, 1) !important", fontWeight: "bold", padding: "1rem 2rem" });
}
export function getColorByValue(value) {
    var redColor = "rgba(239, 96, 96, 1)";
    var yellowColor = "rgba(255, 198, 41, 1)";
    var greenColor = "rgba(0, 178, 97, 1)";
    var abs = Math.abs(value);
    //  red: when value is <= -5  and  >= +5
    //  yellow: when value is <= -3  and  >= +3
    //  green: when value is > -3  and  < +3
    if (abs < 3)
        return greenColor;
    if (abs < 5)
        return yellowColor;
    return redColor;
}
var translations = {
    de: {
        "101": "Keine Paletten für aktuelle Schicht bestätigt",
        "102": "Keine Charge und Paletten für die aktuelle Schicht bestätigt",
        "103": "Keine geplante Produktion für die aktuelle Schicht",
        "104": "Keine Charge und Paletten für die aktuelle Schicht bestätigt",
    },
    en: {
        "101": "No pallets confirmed for current shift",
        "102": "No batch and pallets confirmed for current shift",
        "103": "No scheduled production for current shift",
        "104": "No batch and pallets confirmed for current shift",
    },
    es: {
        "101": "No hay paletas confirmadas para el turno actual",
        "102": "No hay lotes ni palets confirmados para el turno actual",
        "103": "Sin producción programada para el turno actual",
        "104": "No hay lotes ni palets confirmados para el turno actual",
    },
    fr: {
        "101": "Aucune palette confirmée pour le quart de travail actuel",
        "102": "Aucun lot et aucune palette confirmés pour le quart de travail actuel",
        "103": "Aucune production prévue pour l'équipe actuelle",
        "104": "Aucun lot et aucune palette confirmés pour le quart de travail actuel",
    },
    nl: {
        "101": "Geen pallets bevestigd voor huidige dienst",
        "102": "Geen batch en pallets bevestigd voor huidige dienst",
        "103": "Geen geplande productie voor huidige ploeg",
        "104": "Geen batch en pallets bevestigd voor huidige dienst",
    },
    pt: {
        "101": "Nenhum palete confirmado para o turno atual",
        "102": "Nenhum lote e paletes confirmados para o turno atual",
        "103": "Nenhuma produção programada para o turno atual",
        "104": "Nenhum lote e paletes confirmados para o turno atual",
    },
    ru: {
        "101": "Поддоны для текущей смены не подтверждены",
        "102": "Партии и поддоны для текущей смены не подтверждены",
        "103": "Нет запланированного производства на текущую смену",
        "104": "Партии и поддоны для текущей смены не подтверждены",
    },
    ua: {
        "101": "Немає піддонів, підтверджених для поточної зміни",
        "102": "Немає партій і піддонів, підтверджених для поточної зміни",
        "103": "Немає запланованого виробництва для поточної зміни",
        "104": "Немає партій і піддонів, підтверджених для поточної зміни",
    },
};
