var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RoutineActionButton } from "./RoutineActionButton";
import { DWSGroupIcon } from "@dws/dws-styleguide";
export function useRoutineDescription(httpClient, updateRoutine, type) {
    if (type === void 0) { type = "individual"; }
    function RoutineDescription(name, routine) {
        var renderGroupIcon = type === "collaborative" && routine.isShared;
        return (_jsxs("div", __assign({ className: "bl-button-container" }, { children: [_jsx("span", __assign({ className: "bl-text", title: name }, { children: name })), _jsxs("div", __assign({ className: "custom-content" }, { children: [renderGroupIcon && _jsx(DWSGroupIcon, {}), _jsx(RoutineActionButton, { routine: routine, httpClient: httpClient, type: type, updateRoutine: updateRoutine })] }))] })));
    }
    return { RoutineDescription: RoutineDescription };
}
