export function getColorForThreshold(threshold, value, max, inverted, redColor, yellowColor, greenColor) {
    if (inverted === void 0) { inverted = false; }
    if (redColor === void 0) { redColor = "rgba(239, 96, 96, 1)"; }
    if (yellowColor === void 0) { yellowColor = "rgba(255, 198, 41, 1)"; }
    if (greenColor === void 0) { greenColor = "rgba(0, 178, 97, 1)"; }
    var yellowThreshold = threshold.yellowThreshold, redThreshold = threshold.redThreshold;
    if (inverted)
        value = max - value;
    if (value >= redThreshold)
        return redColor;
    if (value >= yellowThreshold)
        return yellowColor;
    return greenColor;
}
