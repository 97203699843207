import { z } from "zod";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
export var RoutineSchema = z.object({
    routineId: z.number(),
    status: z.number(),
    routineName: z.string(),
    routineDescription: z.string(),
    frequency: z.string(),
    createdAt: z.string(),
    deadline: z.string(),
    isShared: z.boolean(),
    isCustom: z.boolean(),
    periodicityId: z.number(),
    periodicityUserId: z.number().nullable().optional(),
    acadiaTaskListUrl: z.string().nullable().optional(),
    acadiaDocument: z.string().nullable().optional(),
    logbookId: z.number(),
    periodicityLogbookId: z.number(),
});
export var RoutineDataSchema = z.object({
    routines: z.array(RoutineSchema),
});
export var routineDataJsonParser = parseFromJSON(RoutineDataSchema);
