import { z } from "zod";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
import { MachineSchema } from "shared-schemas/entities/machine.schema";
var faultLogSchema = z.object({
    machineId: z.string(),
    value: z.string(),
    description: z.string(),
    faultStart: z.date({
        coerce: true,
    }),
    faultEnd: z.date({
        coerce: true,
    }),
});
var aggregationSchema = z.object({
    totalTime: z.number(),
    faultCount: z.number(),
    description: z.string(),
    faults: z.array(faultLogSchema),
});
export var TopFaultsDataSchema = z.object({
    faultAggregations: z.array(aggregationSchema),
    uptimeAggregation: aggregationSchema,
    machine: MachineSchema,
});
export var topFaultsDataJsonParser = parseFromJSON(TopFaultsDataSchema);
