var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from "dws-reports/presentation/components/Title/Title";
import { FaultItem } from "./FaultItem/FaultItem";
import { ErrorDisplay } from "dws-reports/presentation/components/error/ErrorDisplay";
import { useTranslation } from "@dws/dws-profile";
export function TopFaultsList(_a) {
    var data = _a.data, onSelectAggregation = _a.onSelectAggregation, thresholds = _a.thresholds, selectedAggregation = _a.selectedAggregation;
    var t = useTranslation().t;
    if (!(data === null || data === void 0 ? void 0 : data.length))
        return (_jsxs("section", __assign({ className: "top-faults-list" }, { children: [_jsx(Title, { reportName: "top_faults", type: "floating", showDivider: false }), _jsx(ErrorDisplay, { message: t("reports.no_data"), icon: "SMILE" })] })));
    return (_jsxs("section", __assign({ className: "top-faults-list" }, { children: [_jsx(Title, { reportName: "top_faults", type: "floating", showDivider: false }), data.map(function (e) { return (_jsx(FaultItem, { selected: e.description === (selectedAggregation === null || selectedAggregation === void 0 ? void 0 : selectedAggregation.description), thresholds: thresholds, aggregation: e, onClick: onSelectAggregation }, e.description)); })] })));
}
