var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DWSButton, DWSCheckCircleIcon } from "@dws/dws-styleguide";
import { useState } from "react";
import { useTranslation } from "@dws/dws-profile";
import { CustomEventType } from "dws-reports/types/CustomEventType";
import { dispatchCustomEvent } from "dws-reports/hooks/useEventBus";
import { changeActionStatusUsecase } from "dws-reports/modules/report/domain/usecases/changeActionStatusUsecase";
export function ActionUpdatesButton(_a) {
    var action = _a.action, httpClient = _a.httpClient, updateAction = _a.updateAction, _b = _a.showMobileVersion, showMobileVersion = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var _c = useState(""), buttonStatus = _c[0], setButtonStatus = _c[1];
    function setLoading() {
        setButtonStatus(function () { return "loading"; });
    }
    function errorCallback() {
        setButtonStatus(function () { return ""; });
        updateAction(action);
    }
    function completeCallback() {
        dispatchCustomEvent({ type: CustomEventType.REQUEST_REFRESH });
        setButtonStatus(function () { return ""; });
        return M.toast({
            html: t("actions.action_update_success"),
            classes: "toast-success",
            displayLength: 4000,
        });
    }
    var props = {
        httpClient: httpClient,
        action: action,
        loading: buttonStatus === "loading",
        loadingCallback: setLoading,
        completeCallback: completeCallback,
        errorCallback: errorCallback,
        updateAction: updateAction,
    };
    if (showMobileVersion) {
        var CloseIcon = (_jsx(DWSCheckCircleIcon, { fill: "black", title: t("action.close") }));
        var RequestToCloseIcon = (_jsx(DWSCheckCircleIcon, { fill: "black", title: t("action.request_to_close") }));
        var Icon = action.isCloseable ? CloseIcon : RequestToCloseIcon;
        var ReopenIcon = (_jsx(DWSCheckCircleIcon, { fill: "black", title: t("action.reopen") }));
        if (action.isCloseable)
            return _jsx(Button, __assign({}, props, { actionType: "close", icon: Icon }));
        if (action.isRequestableToClose)
            return _jsx(Button, __assign({}, props, { actionType: "requestToClose", icon: Icon }));
        if (action.isReopenable)
            return _jsx(Button, __assign({}, props, { actionType: "reopen", icon: ReopenIcon }));
    }
    return (_jsxs("div", __assign({ className: "actions" }, { children: [action.isCancelable && _jsx(Button, __assign({}, props, { actionType: "cancel" })), action.isCloseable && _jsx(Button, __assign({}, props, { actionType: "close" })), action.isReopenable && _jsx(Button, __assign({}, props, { actionType: "reopen" })), action.isRequestableToClose && (_jsx(Button, __assign({}, props, { actionType: "requestToClose" })))] })));
}
function Button(_a) {
    var actionType = _a.actionType, loading = _a.loading, action = _a.action, httpClient = _a.httpClient, updateAction = _a.updateAction, loadingCallback = _a.loadingCallback, errorCallback = _a.errorCallback, completeCallback = _a.completeCallback, icon = _a.icon;
    var t = useTranslation().t;
    var newStatus = getNewStatus(action, actionType);
    var label = icon ? null : t("reports.".concat(actionType));
    var className = "bl-action-button ".concat(actionType === "cancel" ? "outline" : "");
    function onClick() {
        loadingCallback();
        updateAction(__assign(__assign({}, action), { statusId: newStatus }));
        changeActionStatus(action.id, newStatus, httpClient, loadingCallback, completeCallback, errorCallback);
    }
    return (_jsx(DWSButton, { loading: loading, size: "small", className: className, label: label, onClick: onClick, icon: icon }));
}
var ActionStatusEnum;
(function (ActionStatusEnum) {
    ActionStatusEnum[ActionStatusEnum["Not started"] = 1] = "Not started";
    ActionStatusEnum[ActionStatusEnum["In progress"] = 2] = "In progress";
    ActionStatusEnum[ActionStatusEnum["Requested to close"] = 3] = "Requested to close";
    ActionStatusEnum[ActionStatusEnum["Canceled"] = 4] = "Canceled";
    ActionStatusEnum[ActionStatusEnum["Closed"] = 7] = "Closed";
    ActionStatusEnum[ActionStatusEnum["Delayed"] = 8] = "Delayed";
    ActionStatusEnum[ActionStatusEnum["Disapproved"] = 9] = "Disapproved";
})(ActionStatusEnum || (ActionStatusEnum = {}));
function getNewStatus(action, actionType) {
    var newStatus = {
        cancel: ActionStatusEnum["Canceled"],
        close: ActionStatusEnum["Closed"],
        reopen: ActionStatusEnum["Disapproved"],
        requestToClose: ActionStatusEnum["Requested to close"],
    }[actionType];
    return newStatus;
}
function changeActionStatus(actionId, statusNumber, httpClient, loadingCallback, completeCallback, errorCallback) {
    return __awaiter(this, void 0, void 0, function () {
        var usecase;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingCallback();
                    usecase = changeActionStatusUsecase(httpClient);
                    return [4 /*yield*/, usecase(actionId, statusNumber)
                            .then(completeCallback)
                            .catch(errorCallback)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
