import { calculateDuration } from "./calculateTimeRangeDuration";
import { calculateTimeRangeOverlap } from "./calculateTimeRangeOverlap";
import { add } from "date-fns";
import { getRange } from "./getRange";
import { convertDuration } from "./convertDuration";
export function mapUnitsForTimeRange(data, rangeStart, rangeEnd, selectedRange, selectedView, onlyRest) {
    if (onlyRest === void 0) { onlyRest = false; }
    var _a = getRange(rangeStart, rangeEnd, selectedRange), start = _a.start, end = _a.end;
    var units = [];
    var duration = calculateDuration(start, end);
    var viewUnit = "".concat(selectedView, "s");
    var durationInUnits = convertDuration(duration, viewUnit);
    var _loop_1 = function (i) {
        var _b, _c;
        var unitStart = add(start, (_b = {}, _b[viewUnit] = i, _b));
        var unitEnd = add(start, (_c = {}, _c[viewUnit] = i + 1, _c));
        var unitRange = [unitStart, unitEnd];
        var durationForUnitInMS = data.reduce(function (agg, _a) {
            var faultStart = _a.faultStart, faultEnd = _a.faultEnd;
            var faultRange = [faultStart, faultEnd];
            var duration = calculateTimeRangeOverlap(faultRange, unitRange);
            return agg + duration;
        }, 0);
        if (onlyRest) {
            var unitDurationInMS = calculateDuration(unitStart, unitEnd);
            var uptimeDurationInMS = unitDurationInMS - durationForUnitInMS;
            units.push({
                start: unitStart,
                end: unitEnd,
                dataForUnit: convertDuration(uptimeDurationInMS, viewUnit),
            });
        }
        else {
            units.push({
                start: unitStart,
                end: unitEnd,
                dataForUnit: convertDuration(durationForUnitInMS, viewUnit),
            });
        }
    };
    for (var i = 0; i < durationInUnits; i++) {
        _loop_1(i);
    }
    return units;
}
