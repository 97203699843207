var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer } from "react";
import "./FiveWhyReportControl.scss";
import { useTranslation } from "@dws/dws-profile";
export function FiveWhyReportControl(props) {
    var t = useTranslation().t;
    var children = props.children;
    var _a = useReducer(controlReducer(props), {
        filterCompleted: false,
        filterInProgress: false,
        filterRootCause: false,
    }), filters = _a[0], dispatchFilters = _a[1];
    useEffect(function () {
        cleanupData(props);
    }, [props.options, props.chartInstance]);
    var dispatchFilterCompleted = function () {
        return dispatchFilters({
            payload: { filterCompleted: !filters.filterCompleted },
        });
    };
    var dispatchFilterInProgress = function () {
        return dispatchFilters({
            payload: { filterInProgress: !filters.filterInProgress },
        });
    };
    var dispatchFilterRootCause = function (filterRootCause) {
        return dispatchFilters({
            payload: { filterRootCause: filterRootCause },
        });
    };
    return (_jsxs("section", __assign({ className: "controls" }, { children: [_jsxs("div", __assign({ className: "buttons" }, { children: [_jsx("button", __assign({ "data-testid": "completed-button", className: "button " + (filters.filterCompleted ? "active" : ""), onClick: dispatchFilterCompleted, "aria-pressed": filters.filterCompleted }, { children: t("reports.completed") })), _jsx("button", __assign({ "data-testid": "in-progress-button", className: "button " + (filters.filterInProgress ? "active" : ""), onClick: dispatchFilterInProgress, "aria-pressed": filters.filterCompleted }, { children: t("reports.in_progress") }))] })), _jsx("div", __assign({ style: {
                    height: "100%",
                } }, { children: children })), _jsxs("div", __assign({ className: "switch-custom" }, { children: [_jsx("span", { children: t("reports.root_cause_found") }), _jsx("input", { "data-testid": "root-cause-switch", type: "checkbox", name: "root-cause", id: "root-cause", checked: filters.filterRootCause, onChange: function (event) { return dispatchFilterRootCause(event.target.checked); } }), _jsx("label", { htmlFor: "root-cause" })] }))] })));
}
export function getRawData(props) {
    var options = props.options;
    var series = options.series;
    if (!series)
        return { options: options };
    if (!Array.isArray(series))
        return { options: options };
    var data = series[0].data;
    if (!data)
        return { options: options };
    if (!Array.isArray(data))
        return { options: options };
    return { data: data, options: options };
}
export function cleanupData(props) {
    var chartInstance = props.chartInstance;
    var _a = getRawData(props), data = _a.data, options = _a.options;
    if (!data)
        return { options: options };
    var newData = data.filter(function (item) { return item.value > 0; });
    if (newData.length === 0)
        return { options: options };
    chartInstance === null || chartInstance === void 0 ? void 0 : chartInstance.setOption(__assign(__assign({}, options), { series: [{ data: newData }] }));
    return { data: newData, options: options };
}
export function controlReducer(props) {
    return function (state, action) {
        var _a;
        var newState = __assign(__assign({}, state), action.payload);
        var filterCompleted = newState.filterCompleted, filterInProgress = newState.filterInProgress, filterRootCause = newState.filterRootCause;
        var _b = cleanupData(props), data = _b.data, options = _b.options;
        if (!data)
            return newState;
        var newData = data
            .map(function (item) {
            var name = item.name, notRootCauseFound = item.notRootCauseFound;
            var completedRegex = /Closed|Completed/gi;
            var inProgressRegex = /In Progress/gi;
            if (filterCompleted && !filterInProgress && !completedRegex.test(name))
                return __assign(__assign({}, item), { value: 0 });
            if (filterInProgress && !filterCompleted && !inProgressRegex.test(name))
                return __assign(__assign({}, item), { value: 0 });
            if (filterRootCause)
                return __assign(__assign({}, item), { value: item.value - notRootCauseFound.length });
            return item;
        })
            .filter(function (item) { return item.value > 0; });
        (_a = props.chartInstance) === null || _a === void 0 ? void 0 : _a.setOption(__assign(__assign({}, options), { series: [{ data: newData }] }));
        return newState;
    };
}
