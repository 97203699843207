import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@dws/dws-profile";
export function RoutineFrequency(status) {
    var t = useTranslation().t;
    var frequencyCodes = {
        1: "daily",
        4: "weekly",
        5: "monthly",
        10: "annual",
        13: "as_needed",
    };
    return _jsx("p", { children: t("reports.".concat(frequencyCodes[status])) });
}
