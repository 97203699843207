import { z } from "zod";
import { getActionsDataUsecase } from "../app/modules/report/domain/usecases/getActionsDataUsecase";
import { getCollaborativeRoutinesDataUsecase } from "../app/modules/report/domain/usecases/getCollaborativeRoutinesDataUsecase";
import { getFivewhyDataUsecase } from "../app/modules/report/domain/usecases/getFivewhyDataUsecase";
import { getIndividualRoutinesDataUsecase } from "../app/modules/report/domain/usecases/getIndividualRoutinesDataUsecase";
import { getLinePerformanceDataUsecase } from "../app/modules/report/domain/usecases/getLinePerformanceDataUsecase";
import { getPlanAttainmentDataUsecase } from "../app/modules/report/domain/usecases/getPlanAttainmentDataUsecase";
import { getTopFaultsDataUsecase } from "../app/modules/report/domain/usecases/getTopFaultsDataUsecase";
import { FivewhyDoughnutReport } from "../app/presentation/components/reports/FivewhyDoughnutReport/FivewhyDoughnutReport";
import { LinePerformanceReport } from "../app/presentation/components/reports/LinePerformanceReport/LinePerformanceReport";
import { PlanAttainmentReport } from "../app/presentation/components/reports/PlanAttainmentReport/PlanAttainmentReport";
import { TopFaultsReport } from "../app/presentation/components/reports/TopFaultsReport/TopFaultsReport";
import { reportTypeSchema } from "./reportTypeSchema";
import { ActionsListReport } from "../app/presentation/components/reports/ActionsListReport/ActionsListReport";
import { IndividualRoutinesListReport } from "../app/presentation/components/reports/IndividualRoutinesListReport/IndividualRoutinesListReport";
import { CollaborativeRoutinesListReport } from "../app/presentation/components/reports/CollaborativeRoutinesListReport/CollaborativeRoutinesListReport";
import { FivewhyListReport } from "../app/presentation/components/reports/FivewhyListReport/FivewhyListReport";
export var reportUsecaseDataMap = {
    "line-performance": getLinePerformanceDataUsecase,
    "plan-attainment": getPlanAttainmentDataUsecase,
    "fivewhy-doughnut": getFivewhyDataUsecase,
    "top-faults": getTopFaultsDataUsecase,
    "actions-list": getActionsDataUsecase,
    "individual-routines-list": getIndividualRoutinesDataUsecase,
    "collaborative-routines-list": getCollaborativeRoutinesDataUsecase,
    "fivewhy-list": getFivewhyDataUsecase,
};
export var reportVisualComponentMap = {
    "fivewhy-doughnut": FivewhyDoughnutReport,
    "plan-attainment": PlanAttainmentReport,
    "line-performance": LinePerformanceReport,
    "top-faults": TopFaultsReport,
    "actions-list": ActionsListReport,
    "individual-routines-list": IndividualRoutinesListReport,
    "collaborative-routines-list": CollaborativeRoutinesListReport,
    "fivewhy-list": FivewhyListReport,
};
export var reportFactoryPropsSchema = z.object({
    reportName: reportTypeSchema,
    httpClient: z.any(),
    initialData: z.object({
        loading: z.boolean(),
        data: z.any().nullable().optional(),
        error: z.any().nullable().optional(),
    }),
});
