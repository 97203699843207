import { jsx as _jsx } from "react/jsx-runtime";
import { EchartsContextProvider } from "dws-reports/contexts/echartsContext/echartsContext";
import { EchartsWrapper } from "../../../echarts-wrapper/EchartsWrapper";
import { linePerformanceGenerateOptions } from "./linePerformanceGenerateOptions";
import { generateColorForThresholds } from "./generateColorForThresholds";
export function LinePerformanceInnerReport(_a) {
    var data = _a.data, name = _a.name;
    var target = data.target, dream = data.dream, actual = data.actual;
    var borderRadius = [0, 10, 10, 0];
    var lightGray = "#BFBFBF";
    var darkGray = "#666666";
    var roundedTarget = Number(target === null || target === void 0 ? void 0 : target.toFixed(1));
    var roundedDream = Number(dream === null || dream === void 0 ? void 0 : dream.toFixed(1));
    var roundedActual = Number(actual === null || actual === void 0 ? void 0 : actual.toFixed(1));
    var targetData = [
        {
            value: roundedDream,
            itemStyle: { color: darkGray, borderRadius: borderRadius },
            zlevel: 2,
        },
        {
            value: roundedTarget,
            itemStyle: { color: lightGray, borderRadius: borderRadius },
            zlevel: 1,
        },
    ];
    var getColor = generateColorForThresholds({
        green: target,
        yellow: target * 0.95,
        red: 0,
    });
    var actualData = [actual].map(function (e) { return ({
        value: roundedActual,
        itemStyle: {
            color: getColor(e),
            borderRadius: borderRadius,
        },
    }); });
    var options = linePerformanceGenerateOptions(name, targetData, actualData);
    return (_jsx(EchartsContextProvider, { children: _jsx(EchartsWrapper, { options: options, testId: name, style: {
                position: "absolute",
                height: "50%",
                width: "100%",
                top: name === "GLY" ? 0 : "50%",
            } }) }));
}
