import { z } from "zod";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
export var FivewhyStep = {
    1: "FRONT_LINE_INITIATOR",
    2: "FRONT_LINE_SUPPORT",
    3: "FRONT_LINE_LEADER",
    4: "SUBJECT_MATTER_EXPERT",
    5: "BUSINESS_PROCESS_MANAGER",
    6: "FRONT_LINE_INITIATOR"
};
export var FivewhySchema = z.object({
    fiveWhyId: z.number(),
    fiveWhyName: z.string(),
    rootCauseFound: z.boolean(),
    abReport: z.boolean(),
    canceled: z.boolean(),
    createdAt: z.string(),
    closedAt: z.string().nullable().optional(),
    updatedAt: z.string().nullable().optional(),
    createdBy: z.string(),
    stepId: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
    ])
});
export var FivewhyDataSchema = z.object({
    fivewhys: z.array(FivewhySchema),
});
export var fivewhyDataJsonParser = parseFromJSON(FivewhyDataSchema);
