import { differenceInDays, format } from "date-fns";
import { config } from "@dws/dws-auth";
export function navigateToFiveWhys(_a) {
    var locationId = _a.locationId, areaId = _a.areaId, subareaId = _a.subareaId, equipmentId = _a.equipmentId, machineId = _a.machineId, selectedDate = _a.selectedDate, selectedAggregation = _a.selectedAggregation;
    var basePath = "".concat(config.IAL_BASE_URL, "/problem-terminator/details?");
    var params = new URLSearchParams();
    var problem_details = (selectedAggregation === null || selectedAggregation === void 0 ? void 0 : selectedAggregation.description) || "";
    params.append("locations", locationId.toString());
    params.append("areas", areaId.toString());
    params.append("subareas", subareaId.toString());
    params.append("equipment", equipmentId.toString());
    params.append("machine", machineId.toString());
    if (problem_details)
        params.append("problem_details", problem_details);
    var currentDate = new Date();
    var diff = differenceInDays(currentDate, selectedDate);
    var dateParam = format(diff > 7 ? currentDate : selectedDate, "yyyy-MM-dd");
    params.append("fivewhy_date_report", dateParam);
    window.open(basePath + params.toString(), "_blank");
}
