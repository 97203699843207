var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DWSSelect } from "@dws/dws-styleguide";
import "./TopFaultsSelectors.scss";
import { FiveWhysButton } from "./FiveWhysButton";
import { AcadiaButton } from "./AcadiaButton";
export function TopFaultsSelectors(props) {
    var rangeOptions = [
        { label: "Shift", value: 1 },
        { label: "Day", value: 2 },
        { label: "Week", value: 3 },
    ];
    var viewOptions = [
        { label: "Hour", value: "hour" },
        { label: "Day", value: "day" },
        { label: "Week", value: "week" },
    ];
    return (_jsxs("div", __assign({ className: "top-faults-selectors" }, { children: [_jsx(AcadiaButton, { onClick: props.acadiaCallback }), _jsx(FiveWhysButton, { onClick: props.fivewhyCallback }), _jsx(DWSSelect, { id: "view-selector", options: viewOptions, label: "label", onChange: props.onViewChange, value: props.selectedView, placeholder: "Select a View" }), _jsx(DWSSelect, { id: "range-selector", options: rangeOptions, label: "label", onChange: props.onRangeChange, value: props.selectedRange, placeholder: "Select a Range" })] })));
}
