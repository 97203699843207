export function convertDuration(durationInMs, unit) {
    var durationInSec = durationInMs / 1000;
    var durationInMin = durationInSec / 60;
    var durationInHours = durationInMin / 60;
    var durationInDays = durationInHours / 24;
    var durationInMonths = durationInDays / 30;
    switch (unit) {
        case "seconds":
            return durationInSec;
        case "minutes":
            return durationInMin;
        case "hours":
            return durationInHours;
        case "days":
            return durationInDays;
        case "weeks":
            return durationInDays;
        case "months":
            return durationInMonths;
    }
}
