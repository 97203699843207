var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useReducer } from "react";
var CollaborativeRoutineListContext = createContext(null);
export function useCollaborativeRoutineListContext() {
    var context = useContext(CollaborativeRoutineListContext);
    if (!context) {
        throw new Error("useCollaborativeRoutineListContext must be used within a CollaborativeRoutineListContextProvider");
    }
    return context;
}
export function CollaborativeRoutineListContextProvider(_a) {
    var children = _a.children, data = _a.data;
    var _b = useReducer(dataReducer, data), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        dispatch({ type: "SET_DATA", payload: data });
    }, [data]);
    function updateSingleRoutine(routine) {
        return dispatch({ type: "UPDATE_SINGLE_ROUTINE", payload: routine });
    }
    return (_jsx(CollaborativeRoutineListContext.Provider, __assign({ value: { data: state, updateSingleRoutine: updateSingleRoutine } }, { children: children })));
}
function dataReducer(state, action) {
    switch (action.type) {
        case "SET_DATA":
            return action.payload;
        case "UPDATE_SINGLE_ROUTINE":
            return __assign(__assign({}, state), { routines: state.routines.map(function (routine) {
                    if (routine.routineId === action.payload.routineId) {
                        return action.payload;
                    }
                    return routine;
                }) });
        default:
            return state;
    }
}
