var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DWSTextArea } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";
import { UserAvatar } from "./UserAvatar";
import { FivewhySearchUsersAutocomplete } from "./FivewhySearchUsersAutocomplete";
import { useFivewhyModalContext } from "./FivewhyModalContext";
import "./FivewhyModalContent.scss";
function FivewhyModalContent(_a) {
    var httpClient = _a.httpClient;
    var t = useTranslation().t;
    var _b = useFivewhyModalContext(), modalStep = _b.modalStep, selectedUser = _b.selectedUser, setSelectedUser = _b.setSelectedUser, notes = _b.notes, setNotes = _b.setNotes;
    function handleRemoveUser() {
        setSelectedUser(null);
    }
    ;
    if (!modalStep || modalStep === null)
        return null;
    if (modalStep === "SEND_EMAIL") {
        return (_jsx("span", {}));
    }
    ;
    if (modalStep === "SELECT_USER") {
        return (_jsxs(_Fragment, { children: [selectedUser ?
                    _jsxs("div", __assign({ className: "email-user-card" }, { children: [_jsx(UserAvatar, { name: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.str_name, profilePicture: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.str_img_path }), _jsx("span", __assign({ className: "email-user-name" }, { children: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.str_name })), _jsx("button", { className: "remove-user-button", onClick: handleRemoveUser })] }))
                    : null, !selectedUser ?
                    _jsx("div", __assign({ className: "email-autocomplete-container" }, { children: _jsx(FivewhySearchUsersAutocomplete, { httpClient: httpClient }) }))
                    : null, _jsx("div", __assign({ className: "email-notes-container" }, { children: _jsx(DWSTextArea, { "data-testid": "email-notes-textarea", name: "notes", className: "email-notes-textarea", value: notes, onChange: setNotes, placeholderText: t("reports.email_include_note") }) }))] }));
    }
    ;
}
export { FivewhyModalContent };
