import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ColouredNumberBloc } from "dws-reports/presentation/components/report-helpers/ColouredNumberBloc";
var COLORS = {
    red: "rgba(239, 96, 96, 1)",
    yellow: "rgba(255, 198, 41, 1)",
    green: "rgba(0, 178, 97, 1)",
    gray: "rgba(102, 102, 102, 1)",
};
function getBackgroundColor(value) {
    if (value == null)
        return COLORS.gray;
    if (value < 60)
        return COLORS.red;
    if (value < 80)
        return COLORS.yellow;
    return COLORS.green;
}
function getValue(value) {
    return value == null ? "N/A" : "".concat(value, "%");
}
export default function ReportSection(_a) {
    var title = _a.title, value = _a.value, onClick = _a.onClick;
    return (_jsxs("span", { children: [_jsxs("h6", { children: [title, ":"] }), _jsx(ColouredNumberBloc, { value: value, formatter: getValue, backgroundColor: getBackgroundColor(value), color: "black", onClick: onClick })] }));
}
