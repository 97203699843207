import { useEffect, useReducer, useRef } from "react";
import { reportUsecaseDataMap } from "../../schemas/reportSchemas";
import { useCustomEventBusListener, useEventBusLastCall } from "./useEventBus";
import { FiltersSchema, } from "shared-schemas/filters/filter.schema";
import { CustomEventType } from "../types/CustomEventType";
import { useTranslation } from "@dws/dws-profile";
export function useReportData(reportName, reloadInterval, httpClient) {
    var intervalRef = useRef(null);
    var t = useTranslation().t;
    var _a = useReducer(reportStateReducer, {
        loading: true,
        data: null,
        error: null,
    }, function () { return ({ loading: true, data: null, error: null }); }), state = _a[0], dispatch = _a[1];
    var debounceRef = useRef(null);
    var abortControllerRef = useRef(null);
    useEffect(function () {
        clearInterval(intervalRef.current);
        clearTimeout(debounceRef.current);
        var filters = useEventBusLastCall(CustomEventType.FILTERS_CHANGED);
        if (filters && FiltersSchema.safeParse(filters).success) {
            fetchData(filters);
        }
    }, []);
    useCustomEventBusListener(CustomEventType.FILTERS_CHANGED, function (event) {
        var filters = FiltersSchema.parse(event.payload);
        fetchData(filters);
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(function () { return fetchData(filters); }, reloadInterval);
    });
    useCustomEventBusListener(CustomEventType.REQUEST_REFRESH, function () {
        var filters = useEventBusLastCall(CustomEventType.FILTERS_CHANGED).payload;
        fetchData(filters);
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(function () { return fetchData(filters); }, reloadInterval);
    });
    function fetchData(filters) {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        var _fetchData = function () {
            var dataFetcherFactory = reportUsecaseDataMap[reportName];
            var dataFetcher = dataFetcherFactory(httpClient);
            dataFetcher(filters, abortControllerRef.current.signal)
                .then(function (data) { return dispatch({ type: "success", payload: data }); })
                .catch(function (error) {
                if (error.name === "AbortError")
                    return; // Don't dispatch if request was aborted
                return dispatch({ type: "error", payload: parseError(error, t) });
            });
        };
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(_fetchData, !debounceRef.current ? 1 : 300);
    }
    return state;
}
export function parseError(error, translate) {
    switch (true) {
        case isZodError(error):
            return new Error(translate("reports.invalid_response_from_server"));
        case isSyntaxError(error):
            return new Error(translate("reports.invalid_response_from_server"));
        default:
            return new Error(translate("reports.unknown_error"));
    }
}
export function isZodError(error) {
    return error.name === "ZodError";
}
export function isSyntaxError(error) {
    return error.name === "SyntaxError";
}
export function reportStateReducer(state, action) {
    switch (action.type) {
        case "loading":
            return { loading: true, data: null, error: null };
        case "success":
            return { loading: false, data: action.payload, error: null };
        case "error":
            return { loading: false, error: action.payload, data: null };
        default:
            return state;
    }
}
