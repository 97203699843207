export function generateColorForThresholds(thresholds, redColor, yellowColor, greenColor) {
    if (redColor === void 0) { redColor = "rgba(239, 96, 96, 1)"; }
    if (yellowColor === void 0) { yellowColor = "rgba(255, 198, 41, 1)"; }
    if (greenColor === void 0) { greenColor = "rgba(0, 178, 97, 1)"; }
    return function (value) {
        var higherThanGreen = value >= thresholds.green;
        var higherThanYellow = value >= thresholds.yellow;
        var higherThanRed = value >= thresholds.red;
        if (higherThanGreen)
            return greenColor;
        if (higherThanYellow)
            return yellowColor;
        if (higherThanRed)
            return redColor;
        return redColor;
    };
}
