var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { humanizeDuration } from "../../helpers/humanizeDuration";
import { unitForView } from "../../types";
import { getColorForThreshold } from "../../helpers/getColorForThreshold";
import { convertDuration } from "../../helpers/convertDuration";
import { calculateDuration } from "../../helpers/calculateTimeRangeDuration";
import { useTranslation } from "@dws/dws-profile";
import "./FaultItem.scss";
import { useState } from "react";
export function FaultItem(props) {
    var _a = useState(props.selected), expanded = _a[0], setExpanded = _a[1];
    var t = useTranslation().t;
    var _b = props.aggregation, description = _b.description, faultCount = _b.faultCount, faults = _b.faults, totalTime = _b.totalTime;
    var uid = "fault-item-".concat(description);
    function onClick() {
        props.onClick(props.aggregation);
        setExpanded(!expanded);
    }
    var shouldExpand = expanded && props.selected;
    var className = props.selected ? "fault-item selected" : "fault-item";
    return (_jsx("div", { children: _jsxs("div", __assign({ className: className, "data-testid": uid, onClick: onClick }, { children: [_jsxs("section", __assign({ className: "header" }, { children: [_jsx(Duration, { totalTime: totalTime, thresholds: props.thresholds }), _jsx("span", __assign({ className: "expand", "data-testid": "expand-icon" }, { children: shouldExpand ? "-" : "+" }))] })), _jsx("span", __assign({ className: "description", "data-testid": "description" }, { children: description })), _jsxs("span", __assign({ className: "ocurrences", "data-testid": "ocurrences" }, { children: [t("reports.ocurrences"), ": ", faultCount] })), shouldExpand && (_jsx(InnerLogs, { logs: faults, thresholds: props.thresholds }))] }), uid) }));
}
export function Duration(props) {
    var humanizedDuration = humanizeDuration(props.totalTime);
    var formatedDuration = convertDuration(props.totalTime, unitForView[props.thresholds.alertType]);
    var color = getColorForThreshold(props.thresholds, formatedDuration, 60, false, "rgba(243, 192, 190, 1)", "rgba(255, 208, 173, 1)", "rgba(208, 239, 189, 1)");
    return (_jsx("span", __assign({ className: "duration", "data-testid": "duration", style: { color: color } }, { children: humanizedDuration })));
}
export function InnerLogs(_a) {
    var logs = _a.logs, thresholds = _a.thresholds;
    var t = useTranslation().t;
    logs.sort(function (a, b) {
        return b.faultStart.getTime() -
            b.faultEnd.getTime() -
            (a.faultStart.getTime() - a.faultEnd.getTime());
    });
    var filteredLogs = logs.length > 5 ? logs.slice(0, 5) : logs;
    return (_jsxs("div", __assign({ "data-testid": "inner-logs" }, { children: [filteredLogs.map(function (log) { return (_jsx(Log, { log: log, thresholds: thresholds }, log.faultStart.toISOString())); }), logs.length > 5 && (_jsx("div", __assign({ className: "fault-log" }, { children: _jsxs("span", __assign({ className: "log" }, { children: [t("reports.showing_five_of"), " ", logs.length] })) })))] })));
}
function Log(_a) {
    var log = _a.log, thresholds = _a.thresholds;
    var totalTime = calculateDuration(log.faultStart, log.faultEnd);
    return (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsxs("div", __assign({ className: "fault-log" }, { children: [_jsx("span", __assign({ className: "log" }, { children: log.description })), _jsx(Duration, { totalTime: totalTime, thresholds: thresholds })] }))] }));
}
