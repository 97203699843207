import { startOfDay, startOfWeek, startOfMonth, endOfDay, endOfWeek, endOfMonth, } from "date-fns";
export function getRange(start, end, range) {
    switch (range) {
        case 1:
            return { start: start, end: end };
        case 2:
            return {
                start: startOfDay(start),
                end: endOfDay(end),
            };
        case 3:
            return {
                start: startOfWeek(start),
                end: endOfWeek(end),
            };
        case 4:
            return {
                start: startOfMonth(start),
                end: endOfMonth(end),
            };
        default:
            return { start: start, end: end };
    }
}
