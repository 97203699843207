var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EchartsContext } from "dws-reports/contexts/echartsContext/echartsContext";
import { EchartsWrapper } from "dws-reports/presentation/components/echarts-wrapper/EchartsWrapper";
import { FiveWhyReportControl } from "dws-reports/presentation/components/report-helpers/FiveWhyReportControl";
import { useContext } from "react";
export function formatter(v) {
    var name = v.treePathInfo.map(function (e) { return e.name; }).join(" - ");
    return "".concat(name, ": ").concat(v.value);
}
export function DoughnutReport(_a) {
    var data = _a.data;
    var series = generateEchartsOptions(data);
    var echartsInstance = useContext(EchartsContext).echarts;
    var options = {
        tooltip: {
            trigger: "item",
            formatter: formatter,
        },
        series: [
            {
                name: "Fivewhy",
                type: "sunburst",
                radius: ["50%", "100%"],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: "8px",
                    borderColor: "#292929",
                    borderWidth: 5,
                },
                label: {
                    show: true,
                    position: "inner",
                    rotate: 0,
                    fontSize: 32,
                    fontWeight: "bold",
                    formatter: "{c}",
                },
                labelLine: {
                    show: false,
                },
                data: Object.values(series),
            },
        ],
    };
    var reportStyle = {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "2rem 4rem",
    };
    return (_jsx(FiveWhyReportControl, __assign({ options: options, chartInstance: echartsInstance }, { children: _jsx(EchartsWrapper, { options: options, style: reportStyle }) })));
}
export function generateEchartsOptions(data) {
    var options = {};
    var escalatedFunc = function (data) { return data.abReport; };
    var canceledFunc = function (data) { return data.canceled && !data.abReport; };
    var inProgressFunc = function (data) {
        return !data.closedAt && !data.canceled && !data.abReport;
    };
    var closedFunc = function (data) {
        return !!data.closedAt && !data.canceled && !data.abReport;
    };
    options.escalated = __assign(__assign(__assign({}, addStyle("escalated")), extractRCFound(data, escalatedFunc)), { name: "Escalated to ABR" });
    options.canceled = __assign(__assign(__assign({}, addStyle("canceled")), extractRCFound(data, canceledFunc)), { name: "Canceled" });
    options.inProgress = __assign(__assign(__assign({}, addStyle("inProgress")), extractRCFound(data, inProgressFunc)), { name: "In Progress" });
    options.closed = __assign(__assign(__assign({}, addStyle("closed")), extractRCFound(data, closedFunc)), { name: "Closed" });
    return options;
}
function extractRCFound(data, filter) {
    var filteredData = data.filter(filter);
    var _a = filteredData.reduce(function (prev, aggr) {
        if (aggr.rootCauseFound)
            prev.rootCauseFound.push(aggr);
        else
            prev.notRootCauseFound.push(aggr);
        return prev;
    }, { rootCauseFound: [], notRootCauseFound: [] }), rootCauseFound = _a.rootCauseFound, notRootCauseFound = _a.notRootCauseFound;
    return {
        rootCauseFound: rootCauseFound,
        notRootCauseFound: notRootCauseFound,
        value: filteredData.length,
    };
}
function addStyle(name) {
    var colorMap = {
        escalated: "rgba(65, 111, 203, 1)",
        canceled: "rgba(102, 102, 102, 1)",
        inProgress: "rgba(255, 144, 64, 1)",
        closed: "rgba(0, 178, 97, 1)",
    };
    return {
        itemStyle: {
            color: colorMap[name],
        },
    };
}
