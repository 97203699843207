import { z } from "zod";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
var exampleAction = {
    "id": 1511018,
    "uniqueGeneratedId": null,
    "name": "test",
    "isCritic": false,
    "isFromSplan": null,
    "dueDate": "2023-02-02T00:00:00",
    "createdAt": "2023-02-02T04:43:27.073697",
    "updatedAt": "2023-08-21T19:00:57.740914",
    "deletedAt": null,
    "createdBy": 3307618,
    "updatedBy": null,
    "deletedBy": null,
    "areaId": null,
    "subAreaId": null,
    "equipmentId": null,
    "fiveWhyId": null,
    "ownerId": 3307618,
    "statusId": 8,
    "actionMeeting": {
        "id": 24181874,
        "actionId": 1511018,
        "meetingId": 17281,
        "createdAt": "2023-02-02T04:43:27.073697",
        "updatedAt": "2023-02-02T02:43:27.073697",
        "deletedAt": null,
        "createdBy": 3307618,
        "updatedBy": 3307618,
        "deletedBy": null,
        "isOriginal": true,
        "action": null,
        "meeting": null
    },
    "actionUserStatus": null,
    "status": null,
    "isFinished": false,
    "isCancelable": true,
    "isCloseable": true,
    "isReopenable": false,
    "isRequestableToClose": false
};
var ActionMeetingSchema = z.object({
    id: z.number(),
    actionId: z.number(),
    meetingId: z.number(),
    createdAt: z.string(),
    updatedAt: z.string().nullable().optional(),
    deletedAt: z.string().nullable().optional(),
    createdBy: z.number(),
    updatedBy: z.number().nullable().optional(),
    deletedBy: z.number().nullable().optional(),
    isOriginal: z.boolean(),
    action: z.unknown(),
    meeting: z.unknown(),
});
export var ActionSchema = z.object({
    id: z.number(),
    name: z.string(),
    isCritic: z.boolean().nullable().optional(),
    dueDate: z.string(),
    createdAt: z.string(),
    updatedAt: z.string().nullable().optional(),
    statusId: z.number(),
    statusName: z.string().nullable().optional(),
    ownerId: z.number(),
    actionMeeting: ActionMeetingSchema,
    isFinished: z.boolean(),
    isCancelable: z.boolean(),
    isCloseable: z.boolean(),
    isReopenable: z.boolean(),
    isRequestableToClose: z.boolean(),
});
export var ActionDataSchema = z.object({
    actions: z.array(ActionSchema),
});
export var actionDataJsonParser = parseFromJSON(ActionDataSchema);
