import { joinDateTime } from "./joinDateTime";
import { getUtcISOStringDate } from "./dateFns";
export function calculateShiftStartAndEndTimes(date, shiftStartInput, shiftEndInput) {
    var utcDateString = getUtcISOStringDate(date);
    // returns the start and end of the shift in Local Time
    var shiftStart = joinDateTime(utcDateString, shiftStartInput);
    var shiftEnd = joinDateTime(utcDateString, shiftEndInput);
    if (shiftEnd < shiftStart) {
        shiftEnd = new Date(shiftEnd.setDate(shiftEnd.getDate() + 1));
    }
    return {
        shiftStart: shiftStart,
        shiftEnd: shiftEnd,
    };
}
