import { z } from "zod";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
var PerformanceDataSchema = z.object({
    target: z.number().nullable().optional(),
    actual: z.number().nullable().optional(),
    dream: z.number().nullable().optional(),
});
export var LinePerformanceDataSchema = z.object({
    linePerformance: z.object({
        gly: PerformanceDataSchema,
        lef: PerformanceDataSchema,
    }),
});
export var linePerformanceDataJsonParser = parseFromJSON(LinePerformanceDataSchema);
