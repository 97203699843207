var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { ChartEntrypoint } from "./app/app";
import { ErrorDisplay } from "./app/presentation/components/error/ErrorDisplay";
import { FetchHTTPClient } from "./app/modules/shared/httpClient/impl/fetchHttpClient";
import { AuthenticatedSPA } from "@dws/dws-auth";
import { CONSTANTS } from "./constants";
import { RoutineExecutionReport } from "dws-reports/presentation/components/reports/RoutineExecutionReport/RoutineExecutionReport";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
var httpClient = new FetchHTTPClient();
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: 60000,
        },
    },
});
function RootComponent(_a) {
    var name = _a.name;
    name = name.replace("dws-", "");
    if (name === "routine-execution")
        return (_jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsxs(AuthenticatedSPA, __assign({ clientId: CONSTANTS.AUTH.CLIENT_ID }, { children: [_jsx(RoutineExecutionReport, {}), _jsx(ReactQueryDevtools, { initialIsOpen: false })] })) })));
    return (_jsx(AuthenticatedSPA, __assign({ clientId: CONSTANTS.AUTH.CLIENT_ID }, { children: _jsx(ChartEntrypoint, { reportName: name, httpClient: httpClient }) })));
}
export var lifecycles = singleSpaReact({
    React: React,
    ReactDOM: ReactDOM,
    rootComponent: RootComponent,
    renderType: "render",
    errorBoundary: function (err, info, props) {
        // TODO: Add error logging
        // TODO: make icon configurable from error and props
        return _jsx(ErrorDisplay, { message: err.message, icon: "SMILE" });
    },
});
lifecycles.unmount = function () { return Promise.resolve(); };
export var bootstrap = lifecycles.bootstrap, mount = lifecycles.mount, unmount = lifecycles.unmount;
export default lifecycles;
