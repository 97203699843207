var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./ColouredNumberBloc.scss";
export function ColouredNumberBloc(_a) {
    var value = _a.value, formatter = _a.formatter, color = _a.color, backgroundColor = _a.backgroundColor, onClick = _a.onClick;
    var formattedValue = value === null || value === void 0 ? void 0 : value.toString();
    if (formatter)
        formattedValue = formatter(value);
    var _b = RegExp(/(\d+),\s*(\d+),\s*(\d+)/).exec(backgroundColor), _ = _b[0], r = _b[1], g = _b[2], b = _b[3];
    var greyish = [r, g, b].every(function (match) { return match === r; });
    if (greyish) {
        var luminance = 0.2126 * parseInt(r) + 0.7152 * parseInt(g) + 0.0722 * parseInt(b);
        color = luminance > 155 ? "black" : "rgb(191, 191, 191)";
    }
    var cursor = onClick ? "pointer" : "default";
    return (_jsx("h5", __assign({ onClick: onClick, "data-testid": "coloured-number-bloc", className: "coloured-number-bloc", style: { backgroundColor: backgroundColor, color: color, cursor: cursor } }, { children: formattedValue })));
}
