var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useMemo } from 'react';
var FivewhyModalContext = createContext(null);
export var FivewhyModalProvider = function (_a) {
    var children = _a.children;
    var _b = useState("SEND_EMAIL"), modalStep = _b[0], setModalStep = _b[1];
    var _c = useState(null), selectedUser = _c[0], setSelectedUser = _c[1];
    var _d = useState(null), notes = _d[0], setNotes = _d[1];
    var value = useMemo(function () { return ({
        modalStep: modalStep,
        setModalStep: setModalStep,
        selectedUser: selectedUser,
        setSelectedUser: setSelectedUser,
        notes: notes,
        setNotes: setNotes
    }); }, [modalStep, selectedUser, notes]);
    return _jsx(FivewhyModalContext.Provider, __assign({ value: value }, { children: children }));
};
export var useFivewhyModalContext = function () {
    var context = useContext(FivewhyModalContext);
    if (!context) {
        throw new Error("useFivewhyModalContext must be used within a FivewhyModalProvider");
    }
    return context;
};
