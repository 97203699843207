var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TopFaultsList } from "./TopFaultsList/TopFaultsList";
import { TopFaultsGraphReport } from "./TopFaultsGraphReport/TopFaultsGraphReport";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { getRange } from "./helpers/getRange";
import { TopFaultsSelectors } from "./TopFaultsSelectors/TopFaultsSelectors";
import { useEventBusLastCall } from "dws-reports/hooks/useEventBus";
import { CustomEventType } from "dws-reports/types/CustomEventType";
import { FiltersSchema } from "shared-schemas/filters";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { navigateToAcadia } from "./helpers/navigateToAcadia";
import { navigateToFiveWhys } from "./helpers/navigateToFiveWhys";
import "./TopFaultsReport.scss";
import { useTranslation } from "@dws/dws-profile";
export function TopFaultsReport(_a) {
    var data = _a.data;
    var faultAggregations = data.faultAggregations, uptimeAggregation = data.uptimeAggregation, machine = data.machine;
    faultAggregations.sort(function (a, b) { return b.totalTime - a.totalTime; });
    var _b = useState(faultAggregations[0]), selectedAggregation = _b[0], setSelectedAggregation = _b[1];
    var _c = useState(1), selectedRange = _c[0], setSelectedRange = _c[1];
    var _d = useState("hour"), selectedView = _d[0], setSelectedView = _d[1];
    var t = useTranslation().t;
    var lastFilters = (useEventBusLastCall(CustomEventType.FILTERS_CHANGED) || { payload: null }).payload;
    if (!lastFilters)
        return _jsx(MissingFilter, {});
    var parsedFilters = FiltersSchema.parse(lastFilters);
    if (!parsedFilters.shift)
        return _jsx(MissingFilter, {});
    var _e = [
        parsedFilters.shift.start,
        parsedFilters.shift.end,
    ].map(function (time) {
        return [format(parsedFilters.selectedDate, "yyyy-MM-dd"), "T", time].join("");
    }), formattedShiftStart = _e[0], formattedShiftEnd = _e[1];
    var thresholds = machine.machineThresholds.find(function (mt) { return mt.alertType == selectedView; });
    var _f = getRange(parseISO(formattedShiftStart), parseISO(formattedShiftEnd), selectedRange), start = _f.start, end = _f.end;
    var acadiaCallback = function () {
        return navigateToAcadia({
            areaAcadiaId: parsedFilters.area.acadiaId,
            subareaAcadiaId: parsedFilters.subarea.acadiaId,
            machineAcadiaId: machine.acadiaId,
        });
    };
    var fivewhyCallback = function () {
        return navigateToFiveWhys({
            areaId: parsedFilters.area.areaId,
            equipmentId: parsedFilters.equipment.equipmentId,
            locationId: parsedFilters.location.locationId,
            machineId: machine.machineId,
            selectedDate: parsedFilters.selectedDate,
            subareaId: parsedFilters.subarea.subareaId,
            selectedAggregation: selectedAggregation,
        });
    };
    if (!faultAggregations.length)
        return _jsx(NoData, {});
    if (!uptimeAggregation)
        return _jsx(NoData, {});
    return (_jsxs("div", __assign({ className: "top-faults-report" }, { children: [_jsx(TopFaultsList, { thresholds: thresholds, data: faultAggregations, onSelectAggregation: setSelectedAggregation, selectedAggregation: selectedAggregation }), _jsx(TopFaultsGraphReport, __assign({ aggregation: selectedAggregation, selectedRange: selectedRange, selectedView: selectedView, rangeStart: start, rangeEnd: end, thresholds: thresholds, max: 60, inverted: false, name: t("reports.short_interval_control"), cssSelector: "short-interval-control", acadiaCallback: acadiaCallback, fivewhyCallback: fivewhyCallback }, { children: _jsx(TopFaultsSelectors, { onRangeChange: setSelectedRange, onViewChange: setSelectedView, selectedRange: selectedRange, selectedView: selectedView, filters: parsedFilters, selectedAggregation: selectedAggregation, acadiaCallback: acadiaCallback, fivewhyCallback: fivewhyCallback }) })), _jsx(TopFaultsGraphReport, { aggregation: uptimeAggregation, selectedRange: selectedRange, selectedView: selectedView, rangeStart: start, rangeEnd: end, thresholds: thresholds, max: 60, inverted: true, name: t("reports.uptime"), cssSelector: "uptime-report", acadiaCallback: acadiaCallback, fivewhyCallback: fivewhyCallback })] })));
}
export function MissingFilter() {
    var t = useTranslation().t;
    return _jsx(ErrorDisplay, { message: t("reports.missing_filters"), icon: "SMILE" });
}
export function NoData() {
    var t = useTranslation().t;
    return _jsx(ErrorDisplay, { message: t("reports.no_data"), icon: "SMILE" });
}
