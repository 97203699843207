import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@dws/dws-profile";
var statusColors = {
    1: "#BFBFBF",
    2: "#FFC629",
    3: "#00B261",
    4: "#66666652",
    7: "#00B261",
    8: "#EF6060",
};
var statusTitles = {
    1: "not_started",
    2: "in_progress",
    3: "requested_to_close",
    4: "canceled",
    7: "closed",
    8: "delayed",
};
export function ActionStatusIndicator(_a) {
    var statusId = _a.statusId;
    var t = useTranslation().t;
    if (!statusId)
        return null;
    var status = statusTitles[statusId];
    var activityLabel = t("reports.activity_label");
    var statusLabel = t("reports.".concat(status));
    return (_jsx("span", { role: "status-indicator", title: "".concat(activityLabel, " ").concat(statusLabel), style: { backgroundColor: statusColors[statusId] }, className: "status" }));
}
