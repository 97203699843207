import { jsx as _jsx } from "react/jsx-runtime";
import { EchartsWrapper } from "dws-reports/presentation/components/echarts-wrapper/EchartsWrapper";
import { generateSunburstChartOptions, generateSunburstOptions, } from "./generateSunburstOptions";
export function formatter(v) {
    var name = v.treePathInfo.map(function (e) { return e.name; }).join(" - ");
    return "".concat(name, ": ").concat(v.value);
}
export function SunburstChart(_a) {
    var data = _a.data;
    var opts = generateSunburstOptions(data);
    var series = generateSunburstChartOptions(opts);
    var options = {
        tooltip: {
            trigger: "item",
            formatter: formatter,
        },
        series: [
            {
                name: "Fivewhy",
                type: "sunburst",
                radius: ["10%", "100%"],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: "8px",
                    borderColor: "#292929",
                    borderWidth: 5,
                },
                label: {
                    show: true,
                    position: "inner",
                    rotate: 0,
                    fontSize: 16,
                    fontWeight: "bold",
                    formatter: "{c}",
                },
                labelLine: {
                    show: false,
                },
                data: series,
            },
        ],
    };
    var reportStyle = {
        width: "100%",
        height: "100%",
        padding: "4rem",
        display: "flex",
        justifyContent: "center",
    };
    return _jsx(EchartsWrapper, { options: options, style: reportStyle });
}
