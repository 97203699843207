var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FivewhyStep } from "dws-reports/modules/report/domain/entities/FivewhyData";
import { Table } from "../../Table/Table";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { FivewhyStatusIndicator } from "../../report-helpers/FivewhyStatusIndicator";
import { useTranslation } from "@dws/dws-profile";
import { FivewhyDetailsLink } from "../../report-helpers/FivewhyDetailsLink";
import { useFivewhyDescription } from "../../report-helpers/FivewhyDescription";
import { FivewhyListContextProvider, useFivewhyListContext } from "./FivewhyListContext";
import { useMeasure } from "dws-reports/hooks/useMeasure";
function setDateFieldComponent(date) {
    if (!date)
        return _jsx("span", {});
    var dateObj = new Date(date);
    var formattedDate = new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(dateObj);
    return _jsx("span", { children: formattedDate });
}
function setFivewhyStepComponent(stepId, t) {
    return _jsx("span", { children: t("reports.".concat(FivewhyStep[stepId])) });
}
export function FivewhyListReport(_a) {
    var data = _a.data, httpClient = _a.httpClient;
    var fivewhys = data.fivewhys;
    var EmptyResult = _jsx(ErrorDisplay, { message: "No Fivewhys", icon: "SMILE" });
    if (!fivewhys || fivewhys.length === 0)
        return EmptyResult;
    return (_jsx(FivewhyListContextProvider, __assign({ data: { fivewhys: fivewhys } }, { children: _jsx(FivewhyListTable, { httpClient: httpClient }) })));
}
function setFivewhyStatusIndicator(stepId, fivewhy) {
    return _jsx(FivewhyStatusIndicator, { stepId: stepId, fivewhy: fivewhy });
}
function FivewhyListTable(_a) {
    var httpClient = _a.httpClient;
    var _b = useFivewhyListContext(), data = _b.data, updateSingleFivewhy = _b.updateSingleFivewhy;
    var FivewhyDescription = useFivewhyDescription(httpClient, updateSingleFivewhy).FivewhyDescription;
    var t = useTranslation().t;
    var _c = useMeasure({ eagerObserver: false }), ref = _c[0], width = _c[1].width;
    var isMobile = width < 764;
    var statusColumn = {
        key: "stepId",
        title: t("reports.status"),
        style: { textAlign: "center" },
        customComponent: setFivewhyStatusIndicator,
    };
    var nameColumn = {
        key: "fiveWhyName",
        title: t("reports.description"),
        style: {},
        customComponent: isMobile
            ? null
            : FivewhyDescription,
    };
    var stepColumn = {
        key: "stepId",
        title: t("reports.step"),
        style: { textAlign: "center" },
        customComponent: function (stepId) { return setFivewhyStepComponent(stepId, t); },
    };
    var assigneeColumn = {
        key: "createdBy",
        title: t("reports.assignee"),
        style: { textAlign: "center" },
    };
    var dateColumn = {
        key: "updatedAt",
        title: t("reports.modified_date"),
        style: { textAlign: "center" },
        customComponent: setDateFieldComponent,
    };
    var detailsColumn = {
        key: "fiveWhyId",
        title: t("reports.details"),
        style: { textAlign: "center" },
        customComponent: FivewhyDetailsLink,
        sortable: false,
    };
    var columns = isMobile
        ? [statusColumn, nameColumn, detailsColumn]
        : [statusColumn, nameColumn, stepColumn, assigneeColumn, dateColumn, detailsColumn];
    return (_jsx("div", __assign({ ref: ref, className: "report-table" }, { children: _jsx(Table, { columns: columns, data: data.fivewhys }) })));
}
