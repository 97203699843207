import { jsx as _jsx } from "react/jsx-runtime";
import { z } from "zod";
import { createContext, useContext, useMemo, useState } from "react";
export var EchartsContextSchema = z.object({
    echarts: z.object({}),
    setEcharts: z.function(),
    updateData: z.function(),
    updateOptions: z.function(),
});
export var EchartsContext = createContext(null);
var EchartsContextProviderSchema = z.object({
    children: z.unknown(),
});
export function EchartsContextProvider(_a) {
    var children = _a.children;
    var _b = useState(null), echarts = _b[0], setEcharts = _b[1];
    var value = useMemo(function () { return ({ echarts: echarts, setEcharts: setEcharts }); }, [echarts, setEcharts]);
    return _jsx(EchartsContext.Provider, { value: value, children: children });
}
export function useEchartsContext() {
    return useContext(EchartsContext);
}
