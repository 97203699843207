import { jsx as _jsx } from "react/jsx-runtime";
import { useReportData } from "./hooks/useReportData";
import { ReportFactory } from "./presentation/hocs/ReportFactory";
import { StrictMode } from "react";
var sixtySeconds = 60 * 1000;
export function ChartEntrypoint(_a) {
    var reportName = _a.reportName, httpClient = _a.httpClient, authenticationData = _a.authenticationData;
    var header = "Bearer ".concat(authenticationData === null || authenticationData === void 0 ? void 0 : authenticationData.accessToken);
    httpClient.setAuthenticationHeader(header);
    var initialData = useReportData(reportName, sixtySeconds, httpClient);
    return (_jsx(StrictMode, { children: _jsx(ReportFactory, { initialData: initialData, reportName: reportName, httpClient: httpClient }) }));
}
export default ChartEntrypoint;
