export function setTabCount(className, count) {
    var element = document.getElementsByClassName(className);
    if (element.length > 0) {
        // Remove the current count using a regular expression
        var innerHTMLWithoutCount = element[0].innerHTML.replace(/ \(\d+\)$/, '');
        // Append the new count
        element[0].innerHTML = "".concat(innerHTMLWithoutCount, " (").concat(count, ")");
    }
    // Get the current counts from local storage
    var counts = JSON.parse(localStorage.getItem('tabCounts') || '{}');
    // Update the count for the current class
    counts[className] = count;
    // Save the updated counts back to local storage
    localStorage.setItem('tabCounts', JSON.stringify(counts));
}
