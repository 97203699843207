var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { mapUnitsForTimeRange } from "../helpers/mapUnitsForTimeRange";
import { EchartsContextProvider } from "dws-reports/contexts/echartsContext/echartsContext";
import { EchartsWrapper } from "dws-reports/presentation/components/echarts-wrapper/EchartsWrapper";
import { Title } from "dws-reports/presentation/components/Title/Title";
import { ErrorDisplay } from "dws-reports/presentation/components/error/ErrorDisplay";
import { generateTopFaultsGraphChartOptions } from "./generateTopFaultsGraphChartOptions";
import { useTranslation } from "@dws/dws-profile";
export function TopFaultsGraphReport(_a) {
    var aggregation = _a.aggregation, selectedView = _a.selectedView, selectedRange = _a.selectedRange, rangeStart = _a.rangeStart, rangeEnd = _a.rangeEnd, thresholds = _a.thresholds, max = _a.max, inverted = _a.inverted, name = _a.name, cssSelector = _a.cssSelector, children = _a.children, acadiaCallback = _a.acadiaCallback, fivewhyCallback = _a.fivewhyCallback;
    var t = useTranslation().t;
    var clickHandled = false;
    if (!aggregation)
        return (_jsx(ErrorDisplay, { message: t("reports.no_data_available"), icon: "SMILE" }));
    var data = mapUnitsForTimeRange(aggregation.faults, rangeStart, rangeEnd, selectedRange, selectedView, inverted);
    var chartOptions = generateTopFaultsGraphChartOptions({
        data: data,
        thresholds: thresholds,
        max: max,
        inverted: inverted,
    });
    var chartStyle = {
        height: "30rem",
        width: "100%",
    };
    function onBarClick(event) {
        if (clickHandled)
            return;
        var redThreshold = thresholds.redThreshold, yellowThreshold = thresholds.yellowThreshold;
        var dataIndex = event.dataIndex;
        var dataForUnit = data[dataIndex].dataForUnit;
        var value = max * dataForUnit;
        if (inverted)
            value = max - value;
        if (value >= redThreshold)
            return fivewhyCallback();
        if (value >= yellowThreshold)
            return acadiaCallback();
        clickHandled = true;
        setTimeout(function () {
            clickHandled = false;
        }, 1000);
    }
    return (_jsxs("div", __assign({ className: cssSelector }, { children: [_jsx(Title, __assign({ reportName: name, type: "internal", showDivider: false }, { children: children })), _jsx(EchartsContextProvider, { children: _jsx(EchartsWrapper, { options: chartOptions, style: chartStyle, onClick: onBarClick }) })] })));
}
