var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DWSButton, DWSModal } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";
import { FivewhyModalContent } from "./FivewhyModalContent";
import { FivewhyModalFooter } from "./FivewhyModalFooter";
import { dispatchCustomEvent } from "dws-reports/hooks/useEventBus";
import { CustomEventType } from "dws-reports/types/CustomEventType";
import { updateFivewhyStatusUsecase } from "dws-reports/modules/report/domain/usecases/updateFivewhyStatusUsecase";
import { sendNotificationEmailUsecase } from "dws-reports/modules/report/domain/usecases/sendNotificationEmailUsecase";
import { closeFivewhyUsecase } from "dws-reports/modules/report/domain/usecases/closeFivewhyUsecase";
import { reopenFivewhyUsecase } from "dws-reports/modules/report/domain/usecases/reopenFivewhyUsecase";
import { useFivewhyModalContext } from "./FivewhyModalContext";
function getNextStepId(stepId) {
    return (stepId + 1);
}
function showToast(message, type, duration) {
    if (duration === void 0) { duration = 4000; }
    M.toast({
        html: message,
        classes: type,
        displayLength: duration,
    });
}
function formatDateToISO(date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    var day = String(date.getDate()).padStart(2, '0');
    var hours = String(date.getHours()).padStart(2, '0');
    var minutes = String(date.getMinutes()).padStart(2, '0');
    var seconds = String(date.getSeconds()).padStart(2, '0');
    var milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds, ".").concat(milliseconds);
}
function isReopenValid(fivewhyDate) {
    // should reopen only if the fivewhy was closed in the last 30 days
    var today = new Date();
    var diffTime = Math.abs(today.getTime() - fivewhyDate.getTime());
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 30) {
        return true;
    }
    return false;
}
export function FivewhyUpdatesButton(_a) {
    var fivewhy = _a.fivewhy, httpClient = _a.httpClient, updateFivewhy = _a.updateFivewhy;
    var t = useTranslation().t;
    var stepId = fivewhy.stepId;
    var _b = useState(""), buttonStatus = _b[0], setButtonStatus = _b[1];
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = useFivewhyModalContext(), selectedUser = _d.selectedUser, setSelectedUser = _d.setSelectedUser, notes = _d.notes, setNotes = _d.setNotes, setModalStep = _d.setModalStep;
    useEffect(function () {
        if (fivewhy.canceled || fivewhy.closedAt) {
            var closingDate = fivewhy.canceled ?
                new Date(fivewhy.updatedAt)
                : new Date(fivewhy.closedAt);
            if (!isReopenValid(closingDate)) {
                return setDisabled();
            }
        }
    }, [fivewhy]);
    function setLoading(nextStatusFivewhy) {
        updateFivewhy(__assign({}, nextStatusFivewhy));
        setButtonStatus("loading");
    }
    function setDisabled() {
        setButtonStatus("disabled");
    }
    function setNormal() {
        setButtonStatus("");
    }
    function handleCloseModal() {
        setShowModal(false);
    }
    function completeCallback(message, sendEmail) {
        if (message === void 0) { message = t("reports.success_updating_fivewhy"); }
        if (sendEmail === void 0) { sendEmail = false; }
        if (sendEmail) {
            handleSendEmail();
        }
        setNormal();
        dispatchCustomEvent({ type: CustomEventType.REQUEST_REFRESH });
        setButtonStatus("");
        showToast(message, "toast-success");
    }
    function handleError(fivewhy, message) {
        if (message === void 0) { message = "Error updating fivewhy"; }
        // if error, set to the previous fivewhy status and show toast
        setButtonStatus("");
        updateFivewhy(__assign({}, fivewhy));
        showToast(message, "toast-warning");
    }
    function handleChangeFivewhyStatus() {
        return __awaiter(this, void 0, void 0, function () {
            var emailUserId, nextStepId, nextStepFivewhy, usecase, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!fivewhy)
                            return [2 /*return*/];
                        if (!fivewhy.stepId)
                            return [2 /*return*/];
                        if (fivewhy.stepId >= 6)
                            return [2 /*return*/];
                        emailUserId = (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.cod_user) || null;
                        setShowModal(false);
                        nextStepId = getNextStepId(stepId);
                        nextStepFivewhy = __assign(__assign({}, fivewhy), { stepId: nextStepId });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        setLoading(nextStepFivewhy);
                        usecase = updateFivewhyStatusUsecase(httpClient);
                        return [4 /*yield*/, usecase(nextStepFivewhy, emailUserId, 
                            // if there is a selected user, pass true to send email
                            function () { return completeCallback(t("reports.success_updating_fivewhy"), emailUserId > 0); }, function () { return handleError(fivewhy, t("reports.error_updating_fivewhy")); })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [2 /*return*/, handleError(fivewhy, t("reports.error_updating_fivewhy"))];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleSendEmail() {
        return __awaiter(this, void 0, void 0, function () {
            var emailNotes, emailUserId, usecase, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!fivewhy)
                            return [2 /*return*/];
                        if (!selectedUser)
                            return [2 /*return*/];
                        emailNotes = notes || null;
                        emailUserId = (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.cod_user) || null;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        usecase = sendNotificationEmailUsecase(httpClient);
                        return [4 /*yield*/, usecase(fivewhy, emailUserId, emailNotes, function () { return showToast(t("reports.success_sending_email"), "toast-success"); }, function () { return showToast(t("reports.error_sending_email"), "toast-warning"); })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        showToast(t("reports.error_sending_email"), "toast-warning");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleCloseFivewhy() {
        if (!fivewhy)
            return;
        if (fivewhy.closedAt)
            return handleError(fivewhy, t("reports.error_fivewhy_already_closed"));
        var nextUpdateFivewhy = __assign(__assign({}, fivewhy), { closedAt: formatDateToISO(new Date()) });
        try {
            setLoading(nextUpdateFivewhy);
            var usecase = closeFivewhyUsecase(httpClient);
            usecase(fivewhy, function () { return completeCallback(t("reports.success_closing_fivewhy")); }, function () { return handleError(fivewhy, t("reports.error_closing_fivewhy")); });
        }
        catch (_a) {
            return handleError(fivewhy, t("reports.error_closing_fivewhy"));
        }
    }
    function handleReopenFivewhy() {
        if (!fivewhy)
            return;
        if (!fivewhy.canceled && !fivewhy.closedAt)
            return;
        // if the fivewhy is canceled, use the updatedAt date
        var fivewhyDate = fivewhy.canceled ?
            new Date(fivewhy.updatedAt)
            : new Date(fivewhy.closedAt);
        if (!isReopenValid(fivewhyDate)) {
            return handleError(fivewhy, t("reports.reopen_fivewhy_validation_failed"));
        }
        ;
        var nextUpdateFivewhy = __assign(__assign({}, fivewhy), { closedAt: null, canceled: false });
        try {
            setLoading(nextUpdateFivewhy);
            var usecase = reopenFivewhyUsecase(httpClient);
            usecase(fivewhy, function () { return completeCallback(t("reports.success_reopening_fivewhy")); }, function () { return handleError(fivewhy, t("reports.error_reopening_fivewhy")); });
        }
        catch (_a) {
            return handleError(fivewhy, t("reports.error_reopening_fivewhy"));
        }
    }
    function handleShowModal() {
        // show modal with default states
        setSelectedUser(null);
        setNotes(null);
        setModalStep("SEND_EMAIL");
        setShowModal(true);
    }
    if (!fivewhy)
        return null;
    if (stepId === null || stepId === undefined)
        return null;
    if (stepId < 6 && !fivewhy.canceled && !fivewhy.closedAt) {
        return (_jsxs(_Fragment, { children: [_jsx(DWSButton, { size: "small", className: "bl-action-button", label: t("reports._advance"), onClick: handleShowModal, loading: buttonStatus === "loading", disabled: buttonStatus === "disabled" }), _jsx(DWSModal, __assign({ header: t("reports.email_dialog_title"), visible: showModal, onHide: handleCloseModal, dismissible: true, showCloseIcon: true, footer: _jsx(FivewhyModalFooter, { closeModal: handleCloseModal, confirmSendUpdate: handleChangeFivewhyStatus }) }, { children: _jsx(FivewhyModalContent, { httpClient: httpClient }) }))] }));
    }
    if (stepId === 6 && !fivewhy.canceled && !fivewhy.closedAt) {
        return (_jsx(DWSButton, { size: "small", className: "bl-action-button", label: t("reports.complete"), onClick: handleCloseFivewhy, loading: buttonStatus === "loading", disabled: buttonStatus === "disabled" }));
    }
    if (fivewhy.canceled || fivewhy.closedAt) {
        return (_jsx(DWSButton, { size: "small", className: "bl-action-button", label: t("reports.reopen"), onClick: handleReopenFivewhy, loading: buttonStatus === "loading", disabled: buttonStatus === "disabled" }));
    }
    return null;
}
