var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FivewhyUpdatesButton } from "./FivewhyUpdatesButton";
import { FivewhyModalProvider } from "./FivewhyModalContext";
export function useFivewhyDescription(httpClient, updateFivewhy) {
    function FivewhyDescription(fiveWhyName, fivewhy) {
        return (_jsxs("div", __assign({ className: "bl-button-container" }, { children: [_jsx("span", __assign({ className: "bl-text" }, { children: fiveWhyName })), _jsx("div", __assign({ className: "custom-content" }, { children: _jsx(FivewhyModalProvider, { children: _jsx(FivewhyUpdatesButton, { fivewhy: fivewhy, httpClient: httpClient, updateFivewhy: updateFivewhy }) }) }))] })));
    }
    return { FivewhyDescription: FivewhyDescription };
}
