import { jsx as _jsx } from "react/jsx-runtime";
import { config } from "@dws/dws-auth";
import { navigateToDeepLink } from "dws-reports/functions/navigateToDeepLink";
import { DWSLinkIcon } from "@dws/dws-styleguide";
import { useEventBusLastCall } from "dws-reports/hooks/useEventBus";
import { CustomEventType } from "dws-reports/types/CustomEventType";
export function RoutineDetailsLink(_key, routine) {
    var _a;
    var periodicityId = routine.periodicityId, routineId = routine.routineId, logbookId = routine.logbookId;
    var lastFilters = (useEventBusLastCall(CustomEventType.FILTERS_CHANGED) || {}).payload;
    if (!lastFilters)
        return _jsx(DWSLinkIcon, { title: "details", disabled: true });
    var urlParams = new URLSearchParams(window.location.search);
    var areaId = urlParams.get("area");
    var subAreaId = urlParams.get("subarea");
    var selectedDate = urlParams.get("selectedDate");
    var params = {
        periodicity: periodicityId === null || periodicityId === void 0 ? void 0 : periodicityId.toString(),
        routines: routineId === null || routineId === void 0 ? void 0 : routineId.toString(),
        areas: areaId === null || areaId === void 0 ? void 0 : areaId.toString(),
        subareas: subAreaId === null || subAreaId === void 0 ? void 0 : subAreaId.toString(),
        logbook: logbookId === null || logbookId === void 0 ? void 0 : logbookId.toString(),
        shift: (_a = lastFilters === null || lastFilters === void 0 ? void 0 : lastFilters.shift) === null || _a === void 0 ? void 0 : _a.ialId,
        date: selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.toString(),
    };
    return (_jsx(DWSLinkIcon, { title: "details", onClick: function () {
            return navigateToDeepLink({
                url: "".concat(config.IAL_BASE_URL, "/tasks"),
                params: params,
            });
        } }));
}
