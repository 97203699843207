var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DWSButton } from "@dws/dws-styleguide";
import { useFivewhyModalContext } from "./FivewhyModalContext";
import { useTranslation } from "@dws/dws-profile";
import "./FivewhyModalFooter.scss";
export function FivewhyModalFooter(_a) {
    var closeModal = _a.closeModal, confirmSendUpdate = _a.confirmSendUpdate;
    var t = useTranslation().t;
    var _b = useFivewhyModalContext(), modalStep = _b.modalStep, setModalStep = _b.setModalStep, selectedUser = _b.selectedUser;
    if (!modalStep)
        return null;
    if (modalStep === "SEND_EMAIL") {
        return (_jsxs("div", __assign({ className: "footer-container" }, { children: [_jsx(DWSButton, __assign({ onClick: closeModal, type: "button", className: "button-cancel", "data-testid": "cancel-button" }, { children: t("reports.cancel") })), _jsx(DWSButton, __assign({ onClick: confirmSendUpdate, type: "button", className: "button-send", "data-testid": "send-button" }, { children: t("reports.no") })), _jsx(DWSButton, __assign({ onClick: function () { return setModalStep("SELECT_USER"); }, type: "button", className: "button-confirm", "data-testid": "confirm-button" }, { children: t("reports.yes") }))] })));
    }
    if (modalStep === "SELECT_USER") {
        return (_jsxs("div", __assign({ className: "footer-container" }, { children: [_jsx(DWSButton, __assign({ onClick: closeModal, type: "button", className: "button-cancel" }, { children: t("reports.cancel") })), _jsx(DWSButton, __assign({ disabled: !selectedUser, onClick: confirmSendUpdate, type: "button", className: "button-confirm" }, { children: t("reports.send") }))] })));
    }
    return null;
}
