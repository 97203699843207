import { validateDateInput, validateTimeInput, } from "./dateTimeInputValidators";
export function joinDateTime(date, time) {
    if (!validateDateInput(date)) {
        throw new Error("Invalid date format: ".concat(date));
    }
    if (!validateTimeInput(time)) {
        throw new Error("Invalid time format: ".concat(time));
    }
    var _a = date.split("-"), year = _a[0], month = _a[1], day = _a[2];
    var _b = time.split(":"), hour = _b[0], minute = _b[1], second = _b[2];
    var _c = (second === null || second === void 0 ? void 0 : second.split(".")) || [], secondWithoutZ = _c[0], millisecond = _c[1];
    return new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute), parseInt(secondWithoutZ) || 0, parseInt(millisecond) || 0));
}
