var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@dws/dws-profile";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { Table } from "../../Table/Table";
import { RoutineStatusIndicator } from "../../report-helpers/RoutineStatusIndicator";
import { RoutineDetailsLink } from "../../report-helpers/RoutineDetailsLink";
import { useRoutineDescription } from "../../report-helpers/RoutineDescription";
import { CollaborativeRoutineListContextProvider, useCollaborativeRoutineListContext, } from "./CollaborativeRoutineListContext";
import { useMeasure } from "dws-reports/hooks/useMeasure";
export function CollaborativeRoutinesListReport(_a) {
    var data = _a.data, httpClient = _a.httpClient;
    var routines = data.routines;
    var EmptyResult = _jsx(ErrorDisplay, { message: "No Routines", icon: "SMILE" });
    if (!routines || routines.length === 0)
        return EmptyResult;
    return (_jsx(CollaborativeRoutineListContextProvider, __assign({ data: { routines: routines } }, { children: _jsx(RoutinesTable, { httpClient: httpClient }) })));
}
function RoutinesTable(_a) {
    var httpClient = _a.httpClient;
    var _b = useCollaborativeRoutineListContext(), data = _b.data, updateSingleRoutine = _b.updateSingleRoutine;
    var RoutineDescription = useRoutineDescription(httpClient, updateSingleRoutine, "collaborative").RoutineDescription;
    var t = useTranslation().t;
    var _c = useMeasure({ eagerObserver: false }), ref = _c[0], width = _c[1].width;
    var isMobile = width < 764;
    var statusColumn = {
        key: "status",
        title: t("reports.status"),
        style: { width: "10%", textAlign: "center" },
        customComponent: RoutineStatusIndicator,
    };
    var nameColumn = {
        key: "routineName",
        title: t("reports.description"),
        style: { width: "70%" },
        customComponent: isMobile ? null : RoutineDescription,
    };
    var frequencyColumn = {
        key: "frequency",
        title: t("reports.periodicity"),
        style: { width: "10%" },
    };
    var detailsColumn = {
        key: "details",
        title: t("reports.details"),
        style: { width: "10%", textAlign: "center", cursor: "pointer" },
        customComponent: RoutineDetailsLink,
        sortable: false,
    };
    var columns = isMobile
        ? [statusColumn, nameColumn, detailsColumn]
        : [statusColumn, nameColumn, frequencyColumn, detailsColumn];
    return (_jsx("div", __assign({ ref: ref, className: "report-table" }, { children: _jsx(Table, { columns: columns, data: data.routines }) })));
}
