import { config } from "@dws/dws-auth";
import { format } from "date-fns";
import { navigateToDeepLink } from "dws-reports/functions/navigateToDeepLink";
import { useEventBusLastCall } from "dws-reports/hooks/useEventBus";
import { CustomEventType } from "dws-reports/types/CustomEventType";
export default function navigateTo(sectionType) {
    var _a, _b, _c, _d;
    var IAL_BASE_URL = config.IAL_BASE_URL;
    var filters = useEventBusLastCall(CustomEventType.FILTERS_CHANGED).payload;
    if (!filters)
        return;
    var params = {
        areas: (_a = filters.area.areaId) === null || _a === void 0 ? void 0 : _a.toString(),
        subareas: (_b = filters.subarea.subareaId) === null || _b === void 0 ? void 0 : _b.toString(),
        logbook: (_c = filters.checklist.checklistId) === null || _c === void 0 ? void 0 : _c.toString(),
        shift: (_d = filters.shift.ialId) === null || _d === void 0 ? void 0 : _d.toString(),
        date: format(filters.selectedDate, "yyyy-MM-dd"),
        section: sectionType,
        periodicity: "1",
        cod_action: "1",
    };
    navigateToDeepLink({ url: "".concat(IAL_BASE_URL, "/tasks"), params: params });
}
