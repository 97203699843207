var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { config } from "@dws/dws-auth";
import genericPicture from "../../../../../public/img/generic_profile_picture.jpg";
import "./UserAvatar.scss";
var UserAvatar = function (_a) {
    var name = _a.name, profilePicture = _a.profilePicture;
    var profilePictureUrl = "".concat(config.IAL_UPLOADS_API, "/files/").concat(profilePicture);
    var imageRef = useRef(null);
    var fallbackImageRef = useRef(null);
    var onError = function () {
        if (imageRef.current && fallbackImageRef.current) {
            imageRef.current.src = fallbackImageRef.current.srcset;
        }
    };
    return (_jsx("div", __assign({ className: "dws-avatar" }, { children: _jsxs("picture", { children: [_jsx("source", { ref: fallbackImageRef, srcSet: genericPicture, type: "image/webp" }), _jsx("img", { ref: imageRef, onError: onError, className: "dws-avatar-img", src: profilePictureUrl, alt: name })] }) })));
};
export { UserAvatar };
