import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@dws/dws-profile";
export function RoutineStatusIndicator(status) {
    var t = useTranslation().t;
    var statusColors = {
        1: "#66666652",
        2: "#00B261",
        3: "#FFC629",
        4: "#BFBFBF",
        5: "#66666652",
    };
    var statusTitles = {
        1: "not_done",
        2: "completed",
        3: "pending",
        4: "available",
        5: "not_available",
    };
    return (_jsx("span", { title: "".concat(t("reports.activity_label"), " ").concat(t("reports.".concat(statusTitles[status]))), style: { backgroundColor: statusColors[status] }, className: "status" }));
}
