import { useRef, useState, useLayoutEffect } from "react";
/**
 * hook to measure the width of the component
 * @param {boolean} eagerObserver - if true, the observer will be called immediately
 * if set to false, the observer will be called on the next animation frame, wich may cause a slight delay and flickering
 * if set to true, the observer will be called immediately, which may cause the observer to throw an error "ResizeObserver loop limit exceeded"
 * @returns {array} - [ref, { height, width }]
 */
export function useMeasure(_a) {
    var _b = _a.eagerObserver, eagerObserver = _b === void 0 ? false : _b;
    var ref = useRef(null);
    var _c = useState({ height: 0, width: 0 }), bounds = _c[0], set = _c[1];
    useLayoutEffect(function () {
        var ro = new ResizeObserver(function (_a) {
            var entry = _a[0];
            if (eagerObserver) {
                return set(entry.contentRect);
            }
            return self.requestAnimationFrame(function () { return set(entry.contentRect); });
        });
        if (ref.current) {
            ro.observe(ref.current);
        }
        return function () { return ro === null || ro === void 0 ? void 0 : ro.disconnect(); };
    }, [ref]);
    return [ref, bounds];
}
