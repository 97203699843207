var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@dws/dws-profile";
import { getStatus, getColor, getIcon } from "../../../../app/helpers/fivewhyStatusUtils";
export function FivewhyStatusIndicator(_a) {
    var stepId = _a.stepId, fivewhy = _a.fivewhy;
    if (!stepId || !fivewhy)
        return null;
    var t = useTranslation().t;
    var createdAt = fivewhy.createdAt, closedAt = fivewhy.closedAt, canceled = fivewhy.canceled, abReport = fivewhy.abReport;
    var status = getStatus(createdAt, closedAt, canceled, abReport);
    var color = getColor(status);
    var icon = getIcon(status, color, t);
    return _jsx("span", __assign({ "data-testid": "status-icon" }, { children: icon }));
}
