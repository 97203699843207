export function navigateToAcadia(_a) {
    var areaAcadiaId = _a.areaAcadiaId, subareaAcadiaId = _a.subareaAcadiaId, machineAcadiaId = _a.machineAcadiaId;
    var areaCustomId = "DEEC4FF19974F12ED781CB9A59064214-eql:";
    var equipmentCustomId = "9CCDA2D50A1EE1B73EE1F048EB397AA8-eql:";
    var categoryId = "c1f1dda9-1cfa-4eb6-9e4e2cc8f9d17587";
    var locationId = "c3be9cf4-a337-4b63-9a744828163ffa36";
    var basePath = "https://ab-inbev.acadia.sysalli.com/documents?";
    var params = [];
    params.push(areaCustomId + subareaAcadiaId.toLowerCase());
    params.push("faceted_lang-eql:en-us");
    params.push("Category-eql:" + categoryId);
    params.push(equipmentCustomId + machineAcadiaId.toLowerCase());
    params.push("OrgUnit-eql:" + areaAcadiaId.toLowerCase());
    params.push("Location-eql:" + locationId);
    var finalFilter = params.join("%2b");
    var urlParams = new URLSearchParams();
    urlParams.append("page", "1");
    urlParams.append("pagesize", "50");
    var url = "".concat(basePath).concat(urlParams.toString(), "&filter=").concat(finalFilter);
    window.open(url, "_blank");
}
