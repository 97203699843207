import { endOfMonth, startOfMonth } from "date-fns";
export function calculateMonthStartAndEndTimes(date) {
    var tzOffset = date.getTimezoneOffset();
    if (tzOffset !== 0) {
        date = new Date(date.getTime() - tzOffset * 60 * 1000);
    }
    var start = startOfMonth(date);
    var end = endOfMonth(date);
    return { start: start, end: end };
}
