var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinePerformanceInnerReport } from "./LinePerformanceReport/LinePerformanceInnerReport";
import { Title } from "../../Title/Title";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { useTranslation } from "@dws/dws-profile";
export function LinePerformanceReport(_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var gly = data.gly, lef = data.lef;
    var glyEmpty = [gly.target, gly.actual, gly.dream].every(function (v) { return !v; });
    var lefEmpty = [lef.target, lef.actual, lef.dream].every(function (v) { return !v; });
    if (glyEmpty && lefEmpty) {
        return (_jsx(ErrorDisplay, { message: t("reports.no_data_available"), icon: "SMILE" }));
    }
    return (_jsxs("div", __assign({ style: wrapperStyle }, { children: [_jsx(Title, { reportName: "line_performance" }), _jsxs("div", __assign({ style: innerWrapperStyle }, { children: [_jsx(LinePerformanceInnerReport, { data: gly, name: "GLY" }), _jsx(LinePerformanceInnerReport, { data: lef, name: "LEF" })] }))] })));
}
var innerWrapperStyle = {
    padding: "1rem 2rem",
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    height: "100%",
    position: "relative",
};
var wrapperStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "#292929",
    borderRadius: "8px",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
};
