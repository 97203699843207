import { jsx as _jsx } from "react/jsx-runtime";
import { DWSCheckCircleIcon, DWSCircleIcon, DWSAbReportIcon, DWSStatusCanceledIcon, } from "@dws/dws-styleguide";
export function getStatus(createdAt, closedAt, canceled, abReport) {
    if (canceled)
        return "canceled";
    if (abReport)
        return "abReport";
    var diffInHours = getDiffInHours(createdAt, closedAt);
    return getStatusBasedOnTime(diffInHours, closedAt);
}
function getStatusBasedOnTime(diffInHours, closedAt) {
    var timeBasedStatuses = [
        { threshold: 72, closed: "completed_in_time", open: "in_progress_in_time" },
        { threshold: 120, closed: "completed_late", open: "in_progress_late" },
        {
            threshold: Infinity,
            closed: "completed_very_late",
            open: "in_progress_very_late",
        },
    ];
    for (var _i = 0, timeBasedStatuses_1 = timeBasedStatuses; _i < timeBasedStatuses_1.length; _i++) {
        var _a = timeBasedStatuses_1[_i], threshold = _a.threshold, closed = _a.closed, open = _a.open;
        if (diffInHours <= threshold) {
            return closedAt ? closed : open;
        }
    }
    return "in_progress"; // Default status
}
export function getDiffInHours(createdAt, closedAt) {
    var d1 = new Date(createdAt);
    var d2 = closedAt ? new Date(closedAt) : new Date();
    var diff = Math.abs(d2.getTime() - d1.getTime());
    return diff / 1000 / 60 / 60;
}
export function getColor(status) {
    var statusColors = {
        canceled: "#666666",
        abReport: "#5768FF",
        completed_in_time: "#00B261",
        completed_late: "#FF9040",
        completed_very_late: "#EF6060",
        in_progress_in_time: "#00B261",
        in_progress_late: "#FF9040",
        in_progress_very_late: "#EF6060",
        in_progress: "#666666",
    };
    return statusColors[status];
}
export function getIcon(status, color, t) {
    var iconMap = {
        canceled: (_jsx(DWSStatusCanceledIcon, { fill: color, title: t("reports.canceled") })),
        abReport: (_jsx(DWSAbReportIcon, { fill: color, title: t("reports.escalated_to_ab_report") })),
        completed_in_time: (_jsx(DWSCheckCircleIcon, { fill: color, title: t("reports.completed") })),
        completed_late: (_jsx(DWSCheckCircleIcon, { fill: color, title: t("reports.completed") })),
        completed_very_late: (_jsx(DWSCheckCircleIcon, { fill: color, title: t("reports.completed") })),
        in_progress_in_time: (_jsx(DWSCircleIcon, { fill: color, title: t("reports.in_progress") })),
        in_progress_late: (_jsx(DWSCircleIcon, { fill: color, title: t("reports.in_progress") })),
        in_progress_very_late: (_jsx(DWSCircleIcon, { fill: color, title: t("reports.in_progress") })),
        in_progress: (_jsx(DWSCircleIcon, { fill: color, title: t("reports.in_progress") })),
    };
    return iconMap[status];
}
