import { useEffect, useState } from "react";
import { z } from "zod";
import * as echarts from "echarts";
var useEchartsSchema = z.object({
    ref: z
        .object({
        current: z.any().optional(),
    })
        .required(),
    options: z.object({
        grid: z.any().optional(),
        tooltip: z.any().optional(),
        series: z.array(z.any()),
        xAxis: z.any().optional(),
        yAxis: z.any().optional(),
    }),
});
export function useEcharts(props) {
    var _a = useEchartsSchema.parse(props), ref = _a.ref, options = _a.options;
    var _b = useState(), chart = _b[0], setChart = _b[1];
    useEffect(function () {
        if (ref.current) {
            var fromElement = echarts.getInstanceByDom(ref.current);
            var _chart = fromElement !== null && fromElement !== void 0 ? fromElement : echarts.init(ref.current, {}, { renderer: "canvas" });
            setChart(_chart);
        }
    }, [ref, options]);
    useEffect(function () {
        if (chart && options) {
            chart.setOption(options);
        }
    }, [chart, options]);
    useEffect(function () {
        var resize = function () {
            chart === null || chart === void 0 ? void 0 : chart.resize({
                width: "auto",
                height: "auto",
            });
        };
        self.addEventListener("resize", resize);
        return function () {
            self.removeEventListener("resize", resize);
        };
    }, [chart]);
    return { chart: chart };
}
