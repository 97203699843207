import { jsx as _jsx } from "react/jsx-runtime";
import { reportFactoryPropsSchema, reportVisualComponentMap, } from "../../../schemas/reportSchemas";
import { ErrorDisplay } from "../components/error/ErrorDisplay";
import { LoadingDisplay } from "../components/loading/LoadingDisplay";
export function ReportFactory(props) {
    var _a = reportFactoryPropsSchema.parse(props), reportName = _a.reportName, initialData = _a.initialData, httpClient = _a.httpClient;
    var data = initialData.data, error = initialData.error, loading = initialData.loading;
    var Component = reportVisualComponentMap[reportName];
    if (loading)
        return _jsx(LoadingDisplay, {});
    if (error)
        return _jsx(ErrorDisplay, { message: error.message, icon: "SMILE" });
    return _jsx(Component, { data: data, httpClient: httpClient });
}
