// return a ISO format of the date selected without the effect of the timezone
export function getUtcISOStringFullDay(date) {
    return date.getFullYear() + "-" +
        (date.getMonth() + 1).toString().padStart(2, '0') + "-" +
        date.getDate().toString().padStart(2, '0') +
        "T23:59:59.000Z";
}
// return a ISO format of the end of the date selected without the effect of the timezone
export function getUtcISOStringDate(date) {
    return date.getFullYear() + "-" +
        (date.getMonth() + 1).toString().padStart(2, '0') + "-" +
        date.getDate().toString().padStart(2, '0') +
        "T00:00:00.000Z";
}
