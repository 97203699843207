var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorDisplay } from "../../error/ErrorDisplay";
import { Table } from "../../Table/Table";
import { useTranslation } from "@dws/dws-profile";
import { navigateToDeepLink } from "dws-reports/functions/navigateToDeepLink";
import { ActionStatusIndicator } from "../../report-helpers/ActionStatusIndicator";
import { ActionUpdatesButton } from "../../report-helpers/ActionUpdatesButton";
import { config } from "@dws/dws-auth";
import { DWSLinkIcon } from "@dws/dws-styleguide";
import { ActionListContextProvider, useActionsListContext, } from "./ActionsListContext";
import { useMeasure } from "dws-reports/hooks/useMeasure";
import "./ActionsListReport.scss";
import { setTabCount } from "dws-reports/helpers/setTabCount";
export function createActionStatusComponent(statusId) {
    return _jsx(ActionStatusIndicator, { statusId: statusId });
}
export function createActionDateField(date) {
    var dateObj = new Date(date);
    var formattedDate = new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(dateObj);
    return _jsx("span", { children: formattedDate });
}
export function createActionNameField(updateAction, httpClient, showMobileVersion) {
    if (showMobileVersion === void 0) { showMobileVersion = true; }
    return function (name, action) { return (_jsxs("div", __assign({ className: "bl-button-container" }, { children: [_jsx("span", __assign({ className: "bl-text", title: name }, { children: name })), _jsx(ActionUpdatesButton, { action: action, httpClient: httpClient, updateAction: updateAction, showMobileVersion: showMobileVersion })] }))); };
}
export function createActionDeeplink(actionId) {
    var deepLink = {
        url: "".concat(config.IAL_BASE_URL, "/tasks"),
        params: {
            action: actionId.toString(),
        },
    };
    function navigate(e) {
        e.stopPropagation();
        navigateToDeepLink(deepLink);
    }
    return _jsx(DWSLinkIcon, { title: "details", onClick: navigate });
}
export function ActionsListReport(_a) {
    var data = _a.data, httpClient = _a.httpClient;
    var t = useTranslation().t;
    var actions = data.actions;
    var EmptyResult = (_jsx(ErrorDisplay, { message: t("reports.no_actions"), icon: "SMILE" }));
    if (!actions)
        return EmptyResult;
    if (actions.length === 0)
        return EmptyResult;
    return (_jsx(ActionListContextProvider, __assign({ data: { actions: actions } }, { children: _jsx(ActionsTable, { httpClient: httpClient }) })));
}
function handleActionsSort(actions) {
    var actionIds = actions.map(function (action) { return action.id; });
    setTabCount("sorted-actions", actionIds);
}
function ActionsTable(_a) {
    var httpClient = _a.httpClient;
    var _b = useActionsListContext(), data = _b.data, updateSingleAction = _b.updateSingleAction;
    var _c = useMeasure({ eagerObserver: false }), ref = _c[0], width = _c[1].width;
    var t = useTranslation().t;
    function onRowClick(action) {
        window.location.href = "/actions/".concat(action.id);
    }
    var isMobile = width < 764;
    var statusColum = {
        key: "statusId",
        title: t("reports.status"),
        style: { width: "10%", textAlign: "center" },
        customComponent: createActionStatusComponent,
    };
    var createdAtColumn = {
        key: "createdAt",
        title: t("reports.creation"),
        style: { width: "10%", textAlign: "center" },
        customComponent: createActionDateField,
    };
    var nameColumn = {
        key: "name",
        title: t("reports.name"),
        style: { width: "60%" },
        customComponent: createActionNameField(updateSingleAction, httpClient, isMobile),
    };
    var dueDateColumn = {
        key: "dueDate",
        title: t("reports.deadline"),
        style: { width: "10%", textAlign: "center" },
        customComponent: createActionDateField,
    };
    var linkColumn = {
        key: "id",
        title: t("reports.details"),
        style: { width: "10%", textAlign: "center", cursor: "pointer" },
        customComponent: createActionDeeplink,
        sortable: false,
    };
    var columns = isMobile
        ? [statusColum, nameColumn]
        : [statusColum, createdAtColumn, nameColumn, dueDateColumn, linkColumn];
    return (_jsx("div", __assign({ ref: ref, className: "action-list" }, { children: _jsx(Table, { columns: columns, data: data.actions, onRowClick: onRowClick, onValueChange: handleActionsSort }) })));
}
