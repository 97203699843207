import { linePerformanceTooltipFormatter } from "./linePerformanceTooltipFormatter";
export function linePerformanceGenerateOptions(name, targetData, actualData) {
    return {
        grid: {
            top: 20,
            left: 80,
            right: 80,
            bottom: 40,
        },
        xAxis: {
            type: "value",
            axisLine: {
                onZero: false,
            },
        },
        tooltip: {
            trigger: "axis",
            extraCssText: "width: 150px",
            axisPointer: {
                type: "shadow",
            },
            formatter: linePerformanceTooltipFormatter({
                seriesName: name,
                dream: targetData[0].value,
                target: targetData[1].value,
                value: actualData[0].value,
            }),
        },
        yAxis: yAxisConfig(name),
        series: [
            {
                type: "bar",
                barGap: "-40%",
                name: "Target",
                data: targetData
            },
            {
                name: "Value",
                type: "bar",
                data: actualData,
                label: {
                    show: actualData[0].value > 20,
                    formatter: "{c}%",
                    position: "insideRight",
                    textStyle: {
                        fontSize: "1.2rem",
                        color: "white",
                    },
                },
            },
        ],
    };
}
var yAxisConfig = function (name) { return ({
    axisLabel: {
        fontSize: "1rem",
    },
    type: "category",
    data: [name],
}); };
